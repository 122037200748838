export const currencyFormat = (nStr, suffix = '₫') => {
    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, `$1.$2`);
    }
    return `${x1}${x2} ${suffix}`;
  };
  
  export const formatNumber = (num) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };

  export const paginate = (array, page_number, pageSize = 15) => {
    return array.slice((page_number - 1) * pageSize, page_number * pageSize)
  }

  export const checkPermission = (user, object) => {
    try {
      const { result } = user || {};
      const { roles } = result || {};

      const pageRole = roles.find((r) => r.object === object);
      const { action } = pageRole;

      if (!pageRole || action !== "*") {
        // setIsShowPermissionModal(true);
        return false
      };
      return true;
    } catch (error) {
      console.log(error);
      // setIsShowPermissionModal(true);
      return false;
    }
  };