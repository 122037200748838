import axiosClient from "../axiosClient";

const eventAPI = {
  getEvents: () => {
    const url = `/events`;
    return axiosClient.get(url);
  },
  getEventsByMerchant: (merchantId) => {
    const url = `/events/${merchantId}`;
    return axiosClient.get(url, merchantId);
  },
  getGroupInfoByEvent: (eventId, gte, lte, q, status) => {
    const url = `/events/${eventId}/group-info?q=${q}&payment_status=${status}&gte=${gte}&lte=${lte}`;
    return axiosClient.get(url);
  },
  getCategoriesByEvent: (eventId) => {
    const url = `/event/${eventId}/categories`;
    return axiosClient.get(url);
  },
  getOrderPendingByEvent: (eventId) => {
    const url = `/event/${eventId}/order-pending`;
    return axiosClient.get(url);
  },
};

export default eventAPI;
