import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Col, Button, Form, FormGroup, Label, Input } from "reactstrap";
import {  Menu } from "antd";
import { BrowserView, MobileView } from "react-device-detect";
import { getCurrentUser } from "../../../util/auth";
import adminUserAPI from "../../../api/AdminUserAPI/adminUserAPI";
import Layout from "../../../components/Layout/Layout";
class Profile extends Component {
  state = {
    id: -1,
    user_name: "",
    full_name: "",
    avatar: "https://pix.raceez.com/2019/12/13/default-avatar.jpg",
  };
  id = "";

  user = getCurrentUser();
  componentDidMount() {}

  fetchEditName = async () => {
    try {
      const params = {
        full_name: this.state.full_name,
        avatar: this.state.avatar,
      };

      const response = await adminUserAPI.updateFullName(params, this.user.result.id);
      console.log(response);
      this.setState({ ...response });
    } catch (error) {
      console.log("Faile to fetch", error);
    }
  };
  handleSave = async () => {
    await this.fetchEditName();

    this.props.history.push("/overview");
  };

  handleFormChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    console.log(this.state.user_name);
    return (
      <Layout>
        <MobileView>
          <Menu theme="white" mode="horizontal" defaultSelectedKeys={["1"]}>
            <Menu.Item
              style={{  }}
              key="1"
              onClick={() => {
                this.props.history.push("/profile/rename");
              }}
            >
              Profile
            </Menu.Item>
            <Menu.Item
              style={{ }}
              key="2"
              onClick={() => {
                this.props.history.push("/profile/password");
              }}
            >
             Change Password
            </Menu.Item>
          </Menu>

          <Form
            style={{
              width: "80%",
              marginTop: 60,
              marginLeft: "auto",
              marginRight: "auto",
              textAlign: "webkit-center",
            }}
          >
            <FormGroup row>
              <Label for="exampleEmail" sm={2}>
                User Name
              </Label>
              <Col sm={10}>
                <Input
                  id="user_name"
                  name="user_name"
                  className="form-control"
                  value={this.user.result.user_name}
                  disabled="disabled"
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="exampleEmail" sm={2}>
                Full Name
              </Label>
              <Col sm={10}>
                <Input
                  id="full_name"
                  name="full_name"
                  placeholder="Enter Full Name"
                  value={this.state.full_name}
                  onChange={this.handleFormChange}
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="exampleFile" sm={2}>
                Image
              </Label>
              <Col sm={10}>
                <Input
                  id="avatar"
                  name="avatar"
                  onChange={this.handleFormChange}
                  value={this.user.result.avatar}
                  disabled="disabled"
                />
              </Col>
            </FormGroup>

            <FormGroup check row>
              <Col sm={{ size: 10, offset: 2 }}>
                <Button onClick={this.handleSave}>Submit</Button>
              </Col>
            </FormGroup>
          </Form>
        </MobileView>
        <BrowserView>
          <Menu theme="white" mode="horizontal" defaultSelectedKeys={["0"]}>
            <Menu.Item
              style={{ fontSize: "large" }}
              key="1"
              onClick={() => {
                this.props.history.push("/profile/rename");
              }}
            >
              Profile
            </Menu.Item>
            <Menu.Item
              style={{ fontSize: "large" }}
              key="2"
              onClick={() => {
                this.props.history.push("/profile/password");
              }}
            >
              Change Password
            </Menu.Item>
          </Menu>

          <Form
            style={{
              width: "70%",
              marginTop: 60,
              marginLeft: "auto",
              marginRight: "auto",
              textAlign: "webkit-center",
            }}
          >
            <FormGroup row>
              <Label for="exampleEmail" sm={2}>
                User Name
              </Label>
              <Col sm={10}>
                <Input
                  id="user_name"
                  name="user_name"
                  className="form-control"
                  value={this.user.result.user_name}
                  disabled="disabled"
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="exampleEmail" sm={2}>
                Full Name
              </Label>
              <Col sm={10}>
                <Input
                  id="full_name"
                  name="full_name"
                  placeholder="Enter full name"
                  value={this.state.full_name}
                  onChange={this.handleFormChange}
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="exampleFile" sm={2}>
                Image
              </Label>
              <Col sm={10}>
                <Input
                  id="avatar"
                  name="avatar"
                  onChange={this.handleFormChange}
                  value={this.user.result.avatar}
                  disabled="disabled"
                />
              </Col>
            </FormGroup>

            <FormGroup check row>
              <Col sm={{ size: 10, offset: 2 }}>
                <Button onClick={this.handleSave}>Submit</Button>
              </Col>
            </FormGroup>
          </Form>
        </BrowserView>
      </Layout>
    );
  }
}
export default withRouter(Profile);
