import React, { useEffect, useState } from "react";
import get from "lodash/get";
import { withRouter } from "react-router-dom";
import salesAPI from "../../api/SalesAPI/salesAPI";
import { Select, Button, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import * as classes from "./Attendants.module.css";
import { isMobile } from "react-device-detect";
import { CSVLink } from "react-csv";
import Pagination from "react-js-pagination";
import { getCurrentUser } from "../../util/auth";
import eventAPI from "../../api/EventAPI/eventAPI";
import { isEmpty } from "lodash";
import Layout from "../../components/Layout/Layout";
import PermissionDeniedModal from "../../components/PermissionDeniedModal/PermissionDeniedModal";
import TextModal from "../../components/TextModal/TextModal";
import { checkPermission } from "../../util/helpers";
import Loader from "../../components/Loader/Loader";

const { Option } = Select;

const object = "attendant";

const Attendants = (props) => {
  const { location } = props;
  const { search, pathname } = location;

  const query = new URLSearchParams(search);
  const eventIdQuery = query.get('eventId') || '';
  const pageQuery = query.get('page') || 1;

  const [eventData, setEventData] = useState({
    eventList: [],
    selectedEventId: eventIdQuery
  });
  const [attendantData, setAttendantData] = useState({
    attendantList: [],
    paging: null
  });
  const [categories, setCategories] = useState([]);
  const [attendantsExportData, setAttendantsExportData] = useState();

  const [filterData, setFilterData] = useState({
    id_card: '',
    phone: '',
    email: '',
    category: ''
  });

  const [isShowNoDataModal, setIsShowNoDataModal] = useState(false);
  const [isShowPermissionModal, setIsShowPermissionModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const user = getCurrentUser();

  useEffect(() => {
    const isPermission = checkPermission(user, object);
    if (isPermission) {
      getUserAllowedEvents();
    } else {
      setIsShowPermissionModal(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isEmpty(eventData.eventList)) {
      const id = eventIdQuery || eventData.eventList[0].id;
      getCategories(id);
      getAttendantExportData(id);
      getAttendants(pageQuery, id);
    }
  }, [eventData.eventList]) // eslint-disable-line react-hooks/exhaustive-deps

  const getUserAllowedEvents = async () => {
    const userAllowedEventIds = get(user, 'result.role_extra.event_ids', '');
    const userAllowedMerchantIds = get(user, 'result.role_extra.merchant_ids', '');

    const userAllowedEventIdsArr = get(user, 'result.role_extra.event_ids', '').split(',');
    const userAllowedMerchantIdsArr = get(user, 'result.role_extra.merchant_ids', '').split(',');

    let eventResult = [];

    try {
      if (userAllowedMerchantIds === 'all') { // Show all events from all mechants
        const eventResponse = await eventAPI.getEvents();
        if (!isEmpty(eventResponse)) {
          if (userAllowedEventIds === 'all' || isEmpty(userAllowedEventIds)) {
            eventResult = eventResponse;
          } else {
            eventResult = eventResponse.filter(event => userAllowedEventIdsArr.includes(event.id));
          }
        }
      } else { // Show events based on user merchants
        await Promise.all(userAllowedMerchantIdsArr.map(async merchantId => { // Loop mechants to get merchant's events
          const eventByMerchantIdResponse = await eventAPI.getEventsByMerchant(merchantId);
          if (!isEmpty(eventByMerchantIdResponse)) {
            eventResult = [ ...eventResult, ...eventByMerchantIdResponse ]
          }
        }));
        if (userAllowedEventIds === 'all' || isEmpty(userAllowedEventIds)) {
          //
        } else {
          eventResult = eventResult.filter(event => userAllowedEventIdsArr.includes(event.id));
        }
      }

      setEventData({
        eventList: eventResult,
        selectedEventId: !isEmpty(eventIdQuery) ? eventIdQuery : eventResult[0].id
      });

    } catch (error) {
      setEventData({
        eventList: [],
        selectedEventId: eventIdQuery
      })
      console.log("Faile to fetch", error);
    }
  }

  const getCategories = async (eventId) => {
    try {
      const categoriesResponse = await eventAPI.getCategoriesByEvent(eventId);
      setCategories(categoriesResponse);
    } catch (error) {
      setCategories([]);
      console.log("Faile to fetch", error);
    }
  };

  const getAttendantExportData = async (eventId) => {
    setAttendantsExportData([]);
    try {
      const response = await salesAPI.getAttendantExportDataByEvent(eventId);
      if (response) {
        setAttendantsExportData(response.result)
      }
    } catch (error) {
      setAttendantsExportData([]);
      console.log("Faile to fetch", error);
    }
  };

  const getAttendants = async (page ,eventId) => {
    try {
      const response = await salesAPI.getAttendantsByEvent(
        eventId,
        filterData,
        page
      );

      setAttendantData({
        attendantList: response.data || [],
        paging: response.paging
      })
      window.scrollTo(0, 0);
    } catch (error) {
      setIsShowNoDataModal(true);
      setAttendantData({
        attendantList: [],
        paging: null
      })

      console.log("Faile to fetch", error);
    }
  };

  const updateQuery = (key, value) => {
    let searchParams = new URLSearchParams(search);
    searchParams.set(key, value);
    props.history.push({
      pathname: pathname,
      search: searchParams.toString()
    });
  }

  const _handleEventChange = (value) => {
    const updatedEventData = {...eventData};
    updatedEventData.selectedEventId = value;
    setEventData(updatedEventData);
  }

  const _handleFilterDataChange = (key, value) => {
    setFilterData({
      ...filterData,
      [key]: value
    })
  }

  const _handlePageChange = (page) => {
    updateQuery('page', page);
    getAttendants(page, eventData.selectedEventId);
  }

  const _handleSubmitFilter = async () => {
    props.history.replace({
      pathname: pathname,
      search: "?" + new URLSearchParams({
        eventId: eventData.selectedEventId || '',
        page: 1,
        ...filterData,
      }).toString()
    });

    setIsLoading(true);
    await getCategories(eventData.selectedEventId);
    await getAttendantExportData(eventData.selectedEventId);
    await getAttendants(1, eventData.selectedEventId);
    setIsLoading(false);
  }

  return (
    <Layout>
      <div 
        style={{ marginBottom: "10px" }} 
        className={!isMobile ? "d-flex flex-wrap: wrap justify-content-start align-items-center": ''}>
        <Select
          showSearch
          style={{ 
            width: isMobile ? "100%" : 300,
            marginRight: isMobile ? 0 : 6,
            marginBottom: 5
          }}
          value={get(eventData, 'selectedEventId', '')}
          onChange={(e) => _handleEventChange(e)}
          filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
          optionFilterProp={(optionA, optionB) =>
            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
          }
        >
          {eventData.eventList.map((event) => {
            return (
              <Option key={event.id} value={event.id}>
                {event.name_en}
              </Option>
            );
          })}
        </Select>
        <div 
          className={classes.inputType} 
          style={{
            width: isMobile ? '50%' : 130,
            marginRight: isMobile ? 0 : 6,
            marginBottom: 5
          }}
          >
          <Input
            placeholder="ID card"
            value={filterData.id_card}
            onChange={(e) => _handleFilterDataChange('id_card', e.target.value)}
          />
        </div>
        <div 
          className={classes.inputType} 
          style={{
            width: isMobile ? '50%' : 130,
            marginRight: isMobile ? 0 : 6,
            marginBottom: 5
          }}
        >
          <Input
            placeholder="Phone"
            value={filterData.phone}
            onChange={(e) => _handleFilterDataChange('phone', e.target.value)}
          />
        </div>
        <div 
          className={classes.inputType} 
          style={{
            width: isMobile ? '100%' : 130,
            marginRight: isMobile ? 0 : 6,
            marginBottom: 5
          }}

        >
          <Input
            placeholder="Email"
            value={filterData.email}
            onChange={(e) => _handleFilterDataChange('email', e.target.value)}
          />
        </div>
        <Select
          style={{ 
            width: isMobile ? "100%" : 130,
            marginRight: isMobile ? 0 : 6,
            marginBottom: 5
          }}
          placeholder="Category"
          value={filterData.category || null}
          onChange={(category) => _handleFilterDataChange('category', category)}
          >
          {categories.map((category) => (
            <Option value={category}>
              {category}
            </Option>
          ))}
        </Select>

        <Button
          className={classes.buttonSearch}
          //  className={classes.search}
          type="primary"
          icon={<SearchOutlined />}
          style={{
            width: isMobile ? '48%' :  100,
            marginRight: isMobile ? 0 : 6,
            marginBottom: 5
          }}
          onClick={_handleSubmitFilter}
        >
          Search
        </Button>
        {!isEmpty(attendantsExportData) ? (
          <div style={{ float: "left", width: isMobile ? "48%" : 'fit-content', height: 32, marginBottom: 5 }}>
            <CSVLink
              data={attendantsExportData}
              filename={"attendants.csv"}
              className="btn"
              style={{
                backgroundColor: "#DC143C",
                width: "100%",
                height: 32,
                color: "white",
                fontSize: "13px",
              }}
            >
              Export data
            </CSVLink>
          </div>
        ) : null}
      </div>
      {!isEmpty(attendantData.attendantList) ? (
        <>
          <div style={{overflowX:"auto", width:"100%"}}>
            <table
              className="table table-hover"
              style={{ borderRadius: "10px" }}
            >
              <tbody>
                <tr className={classes.backgroundTr} style={{textAlign:"center"}}>
                  <th scope="col" className={`${classes.backgroundTh} `}>
                    Order Code
                  </th>
                  <th scope="col" className={`${classes.backgroundTh} `}>
                    Full Name
                  </th>
                  <th scope="col" className={`${classes.backgroundTh} `}>
                    Gender
                  </th>
                  <th scope="col" className={`${classes.backgroundTh} `}>
                    Birthday
                  </th>
                  <th scope="col" className={`${classes.backgroundTh} `}>
                    Category
                  </th>
                  <th scope="col" className={`${classes.backgroundTh} `}>
                    Name On Bib
                  </th>
                  <th scope="col" className={`${classes.backgroundTh} `}>
                    Nationality
                  </th>
                  <th scope="col" className={`${classes.backgroundTh} `}>
                    Club
                  </th>
                </tr>
                {attendantData.attendantList.map((attendant, index) => {
                  return (
                    <tr
                      key={index}
                      style={{ fontSize: "14px", textAlign: "center" }}
                    >
                      <td className={`${classes.backgroundTd} `}>
                        <span> {attendant.parent_ref_id} </span>
                      </td>
                      <td className={`${classes.backgroundTd} `}>
                        <span style={{ float: "left", textAlign:"left" }}>
                          
                          {attendant.first_name} {attendant.last_name}
                        </span>
                      </td>
                      <td className={`${classes.backgroundTd} `}>
                        <span style={{textTransform: "capitalize"}}> {attendant.gender} </span>
                      </td>
                      <td className={`${classes.backgroundTd} `}>
                        <span>
                          {" "}
                          {attendant.birthday_day}/{attendant.birthday_month}/
                          {attendant.birthday_year}{" "}
                        </span>
                      </td>
                      <td className={`${classes.backgroundTd} `}>
                        <span> {attendant.category_name_en} </span>
                      </td>
                      <td className={`${classes.backgroundTd} `}>
                        <span> {attendant.name_on_bib} </span>
                      </td>
                      <td className={`${classes.backgroundTd} `}>
                        <span style={{textTransform: "capitalize"}}> {attendant.nationality} </span>
                      </td>
                      <td className={`${classes.backgroundTd} `}>
                        <span> {attendant.club_name} </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className={classes.pagination}>
            {attendantData.paging !== null &&
            attendantData.paging.total_item > 20 ? (
              <Pagination
                hideFirstLastPages
                pageRangeDisplayed={7}
                activePage={attendantData.paging.current_page}
                itemsCountPerPage={20}
                totalItemsCount={attendantData.paging.total_item}
                onChange={_handlePageChange}
                itemClass="page-item"
                linkClass="page-link"
              />
            ) : null}
          </div>
        </>
      ) : null}
      <Loader isOpen={isLoading} />
      <PermissionDeniedModal 
        isOpen={isShowPermissionModal} 
        toggle={() => setIsShowPermissionModal(!isShowPermissionModal)}
      />
      <TextModal 
        isOpen={isShowNoDataModal} 
        toggle={() => setIsShowNoDataModal(!isShowNoDataModal)}
        bodyText={'No Data'}
      />
    </Layout>
  );
}
export default withRouter(Attendants);
