import React, { useEffect, useState } from "react";
import adminUserAPI from "../../api/AdminUserAPI/adminUserAPI";
import { withRouter } from "react-router-dom";
import * as classes from '../Table.module.css';
import Switch from "react-switch";
import { getCurrentUser } from "../../util/auth";
import Layout from "../../components/Layout/Layout";
import TextModal from "../../components/TextModal/TextModal";
import PermissionDeniedModal from "../../components/PermissionDeniedModal/PermissionDeniedModal";
import { checkPermission } from "../../util/helpers";

const object = "user";

const Users = (props) => {

  const [users, setUsers] = useState([]);
  const [resetPasswordValue, setResetPasswordValue] = useState('');

  const [isShowNoDataModal, setIsShowNoDataModal] = useState(false);
  const [isShowPermissionModal, setIsShowPermissionModal] = useState(false);
  const [isShowPasswordModal, setIsShowPasswordModal] = useState(false);

  // const [isLoading, setIsLoading] = useState(false);

  const user = getCurrentUser();

  useEffect(() => {
    const isPermission = checkPermission(user, object);
    if (isPermission) {
      getUsers();
    } else {
      setIsShowPermissionModal(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getUsers = async () => {
    try {
      const response = await adminUserAPI.getAdminUsers();
      if (response) {
        setUsers(response.result);
      }
    } catch (error) {
      setIsShowNoDataModal(true);
      console.log("Faile to fetch", error);
    }
  };

  const _handleActivationChange = async (checked, e, index) => {
    const isActive = +checked;

    let updatedUsers = [...users];
    if (!updatedUsers[index]) return;

    try {
      const response = await adminUserAPI.updateActivation(updatedUsers[index].id, isActive);
      if (response) {
        console.log(response);
        updatedUsers[index].is_active = isActive;
        setUsers(updatedUsers);
      }
    } catch (error) {
      console.log("Faile to fetch", error);
    }
  };

  const _handleResetPassword = async (id) => {
    try {
      const response = await adminUserAPI.resetPassword(id);
      if (response) {
        console.log(response);
        setResetPasswordValue(response.password);
        setIsShowPasswordModal(true);
      }    
    } catch (error) {
      setResetPasswordValue('');
      console.log("Faile to fetch", error);
    }
  };

  const _navigateEditPage = (id) => {
    props.history.push(`/users/${id}`);
  };

  const _navigateCreatePage = () => {
    props.history.push("/users/create");
  };
   
  return (
    <Layout>
      <div style={{paddingBottom: '10px'}}>
        <span 
          style={{fontSize: '30px', fontWeight: 'bold'}}
          >
          User admin
        </span>
        <button
          onClick={_navigateCreatePage}
          type="button"
          className="btn btn-primary float-right"
        >
          Create user
        </button>
      </div>
      <div>
        <div className="table-responsive">
          <table className="table table-hover">
            <tbody>
              <tr className={classes.backgroundTr}>
                <th scope="col" className={classes.backgroundTh}>#</th>
                <th scope="col" className={classes.backgroundTh}>Avatar</th>
                <th scope="col" className={classes.backgroundTh}>Username</th>
                <th scope="col" className={classes.backgroundTh}>Full name</th>
                <th scope="col" className={classes.backgroundTh}>Active</th>
                <th scope="col" className={classes.backgroundTh}>Password</th>
              </tr>
            {users.map((user, index) =>  {
              let position = index + 1;
              return (
              <tr key={index}>
                <td className={classes.backgroundTd}>
                  <span> {position} </span>
                </td>
                <td className="image" >
                  {" "}
                  <img className="rounded-circle" style={{ width: 50, height: 50, }} src={user.avatar} alt=" " />{" "}              
                </td>
                <td 
                className={classes.backgroundTd} 
                style={{color: '#1890ff', cursor: 'pointer'}}
                onClick={() => _navigateEditPage(user.id)}
                >
                  <span> {user.user_name} </span>
                </td>
                <td className={classes.backgroundTd} style={{color: '#52c41a' , textTransform: 'capitalize'}}>
                  <span> {user.full_name} </span>
                </td>
                <td>
                  <Switch
                    id={index.toString()}
                    itemID={index.toString()}
                    onChange={_handleActivationChange}
                    checked={(+user.is_active === 1) ? true : false}>
                  </Switch>
                </td>
                <td>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => _handleResetPassword(user.id)}>
                    Reset
                  </button>
                </td>
              </tr>
              );
            })}
            </tbody>
          </table>
        </div>
      </div>
      <TextModal 
        isOpen={isShowNoDataModal} 
        toggle={() => setIsShowNoDataModal(!isShowNoDataModal)}
        bodyText={'No Data'}
      />
      <TextModal 
        isOpen={isShowPasswordModal} 
        toggle={() => setIsShowPasswordModal(!isShowPasswordModal)}
        headerText={'Password'}
        bodyText={resetPasswordValue}
      />
      <PermissionDeniedModal 
        isOpen={isShowPermissionModal} 
        toggle={() => setIsShowPermissionModal(!isShowPermissionModal)}
      />
    </Layout>
  );
}
export default withRouter(Users);
