import React from 'react';

const Card = ({ children, style, backgroundColor, className }) => {
  return ( 
    <div style={{
      ...style, 
      backgroundImage: backgroundColor
    }} className={`${className}`}>
      {children}
    </div>
  );
}

export default Card;