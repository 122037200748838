import axiosClient from "../axiosClient";

const adminUserAPI = {
  getAdminUsers: (params) => {
    const url = `/user-admin`;
    return axiosClient.get(url);
  },
  getAdminUserDetail: (params, id) => {
    const url = `/user-admin/${id}`;
    return axiosClient.get(url, params);
  },

  login: (params) => {
    const url = "/user-admin/login";
    return axiosClient.post(url, params);
  },
  createAdminUser: (params) => {
    const url = `/user-admin/register`;
    return axiosClient.post(url, params);
  },

  resetPassword: (id) => {
    const url = `/user-admin/${id}/password/reset`;
    return axiosClient.put(url, {});
  },
  changePassword: (params, id) => {
    const url = `/user-admin/${id}/password`;
    return axiosClient.put(url, params);
  },
  updateFullName: (params, id) => {
    const url = `/user-admin/${id}`;
    return axiosClient.put(url, params);
  },
  updateActivation: (id, isActive) => {
    const url = `/user-admin/${id}/active/${isActive}`;
    return axiosClient.put(url);
  },
  updateRoles: (params, id) => { // user, overview, attendant, order
    const url = `/user-admin/${id}/role`;
    return axiosClient.put(url, params);
  },
  updateExtraRoles: (param, id) => { // event_ids, merchant_ids
    const url = `/user-admin/${id}/role-extra`;
    return axiosClient.put(url, param);
  },
};

export default adminUserAPI;
