import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import salesAPI from "../../api/SalesAPI/salesAPI";
import eventAPI from '../../api/EventAPI/eventAPI';


import * as classes from "./OverviewTable.module.css";
import { Select, Button, Divider, DatePicker } from "antd";
import { SearchOutlined } from "@ant-design/icons";
// import { Line } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import moment from "moment";
import { getCurrentUser } from "../../util/auth";
import { isEmpty, get } from "lodash";
import Layout from "../../components/Layout/Layout";
import SummaryCard from "./SummaryCard/SummaryCard";
import TicketsInfoTable from "./TicketsInfoTable/TicketsInfoTable";
import { checkPermission, currencyFormat, formatNumber } from "../../util/helpers";
import OrderPendingTable from "./OrderPendingTable/OrderPendingTable";
import TShirtSizeTable from "./TShirtSizeTable/TShirtSizeTable";
import AttendantInfoTable from "./AttendantInfoTable/AttendantInfoTable";
import GroupInfoTable from "./GroupInfoTable/GroupInfoTable";
import PermissionDeniedModal from "../../components/PermissionDeniedModal/PermissionDeniedModal";
import TextModal from "../../components/TextModal/TextModal";
import { logout } from "../../util/auth";

const object = "overview";
const { Option } = Select;
const { RangePicker } = DatePicker;

const Overview = (props) => {
  const { location } = props;
  const { search } = location;

  const query = new URLSearchParams(search);
  const eventIdQuery = query.get('eventId') || '';
  const fromDateQuery = query.get('fromDate') || '';
  const toDateQuery = query.get('toDate') || '';

  const [eventData, setEventData] = useState({
    eventList: [],
    selectedEventId: eventIdQuery
  });

  const [dateRange, setDateRange] = useState({
    fromDate: fromDateQuery,
    toDate: toDateQuery
  });

  const [summarySaleInfo, setSummarySaleInfo] = useState();
  const [ticketInfo, setTicketInfo] = useState();
  const [orderPendingInfo, setOrderPendingInfo] = useState();
  const [sizeInfo, setSizeInfo] = useState();
  const [attendantInfo, setAttendantInfo] = useState();
  const [groupInfo, setGroupInfo] = useState();

  const [isShowNoDataModal, setIsShowNoDataModal] = useState(false);
  const [isShowPermissionModal, setIsShowPermissionModal] = useState(false);

  const user = getCurrentUser();

  useEffect(() => {
    checkExpiredToken();
  }, []);

  const checkExpiredToken = () => {
    const time = localStorage.getItem('time');
    const expiredTime = localStorage.getItem('expiredTime');

    if (!time) {
      const currentTime = moment();
      const exTime = moment().add(60, 'minutes');
      localStorage.setItem('time', currentTime);
      localStorage.setItem('expiredTime', exTime);
    } else {
      const currentTime = moment();

      const expired = moment(currentTime).isBetween(time, expiredTime);

      if (!expired) {
        window.alert('Phiên của bạn đã hết hạn. Vui lòng đăng nhập lại');
        logout();
        localStorage.removeItem('time');
        localStorage.removeItem('expiredTime');
        window.location.href="/login";
      } else {
        const currentTime = moment();
        const exTime = moment().add(60, 'minutes');
        localStorage.setItem('time', currentTime);
        localStorage.setItem('expiredTime', exTime);
      }
    }
  }

  useEffect(() => {
    const isPermission = checkPermission(user, object);
    if (isPermission) {
      // alert('Accessed')
      getUserAllowedEvents();
    } else {
      setIsShowPermissionModal(true);
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isEmpty(eventData.eventList)) {
      const id = eventIdQuery || eventData.eventList[0].id;
      getSummarySalesEvent(id, fromDateQuery, toDateQuery);
    }
  }, [eventData.eventList]) // eslint-disable-line react-hooks/exhaustive-deps

  const getUserAllowedEvents = async () => {
    const userAllowedEventIds = get(user, 'result.role_extra.event_ids', '');
    const userAllowedMerchantIds = get(user, 'result.role_extra.merchant_ids', '');

    const userAllowedEventIdsArr = get(user, 'result.role_extra.event_ids', '').split(',');
    const userAllowedMerchantIdsArr = get(user, 'result.role_extra.merchant_ids', '').split(',');

    let eventResult = [];

    try {
      if (userAllowedMerchantIds === 'all') { // Show all events from all mechants
        const eventResponse = await eventAPI.getEvents();
        if (!isEmpty(eventResponse)) {
          if (userAllowedEventIds === 'all' || isEmpty(userAllowedEventIds)) {
            eventResult = eventResponse;
            eventResult.unshift({
              id: 'all',
              name_en: 'All Event',
              name_vi: 'Tất cả'
            });  
          } else {
            eventResult = eventResponse.filter(event => userAllowedEventIdsArr.includes(event.id));
          }
        }
      } else { // Show events based on user merchants
        await Promise.all(userAllowedMerchantIdsArr.map(async merchantId => { // Loop mechants to get merchant's events
          const eventByMerchantIdResponse = await eventAPI.getEventsByMerchant(merchantId);
          if (!isEmpty(eventByMerchantIdResponse)) {
            eventResult = [ ...eventResult, ...eventByMerchantIdResponse ]
          }
        }));
        if (userAllowedEventIds === 'all' || isEmpty(userAllowedEventIds)) {
          //
        } else {
          eventResult = eventResult.filter(event => userAllowedEventIdsArr.includes(event.id));
        }
      }

      setEventData({
        eventList: eventResult,
        selectedEventId: !isEmpty(eventIdQuery) ? eventIdQuery : eventResult[0].id
      });

    } catch (error) {
      console.log("Faile to fetch", error);
    }
  }

  const _handleInitialOverviewInfo = () => {
    setSummarySaleInfo();
    setTicketInfo();
    setOrderPendingInfo();
    setSizeInfo();
    setAttendantInfo();
    setGroupInfo();
  }

  const getSummarySalesEvent = async (id = '', fromDate = '', toDate = '') => {
    props.history.replace({
      pathname: `/${object}`,
      search: "?" + new URLSearchParams({
        eventId: id || '',
        fromDate: fromDate || '',
        toDate: toDate || ''
      }).toString()
    });

    try {
      const summarySalesResponse = id === 'all' 
      ? await salesAPI.getSummarySales(fromDate, toDate)
      : await salesAPI.getSummarySalesByEvent(id, fromDate, toDate);
      if (!isEmpty(summarySalesResponse)) {
        setSummarySaleInfo(summarySalesResponse);
        if (id === 'all') { // Only show Summary Sales
          setTicketInfo();
          setOrderPendingInfo();
          setSizeInfo();
          setAttendantInfo();
          setGroupInfo();  
        } else {
          getTicketInfo(id, fromDate, toDate);
          getOrderPendingInfo(id);
          getSizeInfo(id, fromDate, toDate);
          getAttendantInfo(id, fromDate, toDate);
          getGroupInfo(id, fromDate, toDate, '', '');        
        }
      }
    } catch (error) {
      console.log("Faile to fetch", error);
      _handleInitialOverviewInfo();
      setIsShowNoDataModal(true);
    }
  };

  const getTicketInfo = async (eventId = '', fromDate = '', toDate = '') => {
    try {
      const ticketInfoResponse = await salesAPI.getTicketInfoByEvent(eventId, fromDate, toDate);
      if (!isEmpty(ticketInfoResponse)) {
        setTicketInfo(ticketInfoResponse)
      }
    } catch (error) {
      console.log("Faile to fetch", error);
      setTicketInfo();
    }
  };

  const getOrderPendingInfo = async (eventId) => {
    try {
      const orderPendingResponse = await eventAPI.getOrderPendingByEvent(eventId);
      if (!isEmpty(orderPendingResponse)) {
        setOrderPendingInfo(orderPendingResponse)
      }
    } catch (error) {
      console.log("Faile to fetch", error);
      setOrderPendingInfo();
    }
  };

  const getSizeInfo = async (eventId, fromDate = '', toDate = '') => {
    try {
      const sizeInfoResponse = await salesAPI.getSizeInfoByEvent(eventId, fromDate, toDate);
      if (!isEmpty(sizeInfoResponse)) {
        setSizeInfo(sizeInfoResponse);
      }
    } catch (error) {
      console.log("Faile to fetch", error);
      setSizeInfo();
    }
  };

  const getAttendantInfo = async (eventId, fromDate = '', toDate = '') => {
    try {
      const attendantInfoResponse = await salesAPI.getAttendantInfoByEvent(eventId, fromDate, toDate);
      if (!isEmpty(attendantInfoResponse)) {
        setAttendantInfo(attendantInfoResponse);
      }
    } catch (error) {
      console.log("Faile to fetch", error);
      setAttendantInfo();
    }
  };

  const getGroupInfo = async (eventId, fromDate, toDate, filter, stt) => {
    try {
      const groupInfoResponse = await eventAPI.getGroupInfoByEvent(eventId, fromDate, toDate, filter, stt);
      if (groupInfoResponse.result) {
        setGroupInfo(groupInfoResponse.data)
      }
    } catch (error) {
      console.log("Faile to fetch", error);
      setGroupInfo();
    }
  };

  const _handleDateChange = async (value) => {
    if (value === null) {
      return setDateRange({
        fromDate: '',
        toDate: ''
      })
    };
    const fromDateChosen = value[0].format("YYYY-MM-DD");
    const toDateChosen = value[1].format("YYYY-MM-DD");

    setDateRange({
      fromDate: fromDateChosen,
      toDate: toDateChosen
    });
  }

  const _handleEventChange = (value) => {
    const updatedEventData = {...eventData};
    updatedEventData.selectedEventId = value;
    setEventData(updatedEventData);
  }

  const _handleSubmitFilter = () => {
    getSummarySalesEvent(eventData.selectedEventId, dateRange.fromDate, dateRange.toDate);
    getGroupInfo(eventData.selectedEventId, '', '', '', '');
  }

  return (
    <Layout>
      <div style={{ marginBottom: "10px" }}>
        <Select
          showSearch
          className={`${classes.selectEventBrowser} mb-3 mr-3`}
          value={get(eventData, 'selectedEventId', '')}
          style={{padding: 0}}
          onChange={(e) => _handleEventChange(e)}
          filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
          optionFilterProp={(optionA, optionB) =>
            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
          }
        >
          {get(eventData, 'eventList', []).map((event) => {
            return (
              <Option key={event.id} value={event.id}>
                {event.name_en}
              </Option>
            );
          })}
        </Select>
        <RangePicker
          className="mb-3 mr-3"
          style={{width: 300}}
          onChange={(e) => _handleDateChange(e)}
          bordered={true}
          defaultValue={
            [
              (isEmpty(dateRange.fromDate) && isEmpty(dateRange.toDate)) 
              ? "" 
              : moment(dateRange.toDate), (dateRange.fromDate === "") ? "" : moment(dateRange.fromDate)]}
        />

        <Button
          className={classes.search}
          style={{margin: 0}}
          type="primary"
          icon={<SearchOutlined />}
          onClick={() => _handleSubmitFilter()}
        >
          Search
        </Button>
      </div>
      <div className="d-flex flex-wrap">
        <SummaryCard 
          label={'TRANSACTION'} 
          value={formatNumber(get(summarySaleInfo, 'sales_info.total_transaction', 0))} 
          icon={'https://pix.raceez.com/2020/12/09/icons8-transaction-list-64_(1).png'} 
          backgroundColor={'-webkit-linear-gradient(90deg,#38ef7d 0%, #11998e 100%)'}
        />
        <SummaryCard 
          label={'ITEM'} 
          value={formatNumber(get(summarySaleInfo, 'sales_info.total_item', 0))} 
          icon={'https://pix.raceez.com/2020/12/09/icons8-ticket-confirmed-50.png'} 
          backgroundColor={'-webkit-linear-gradient(90deg,#38ef7d 0%, #11998e 100%)'}
        />
        <SummaryCard 
          label={'REVENUE BEFORE DISCOUNT'} 
          value={currencyFormat(get(summarySaleInfo, 'sales_info.sub_total', 0))} 
          icon={'https://pix.raceez.com/2020/12/11/icons8-us-dollar-64_20201211060809.png'} 
          backgroundColor={'-webkit-linear-gradient(90deg,#38ef7d 0%, #11998e 100%)'}
        />
        <SummaryCard 
          label={'REVENUE AFTER DISCOUNT'} 
          value={currencyFormat(get(summarySaleInfo, 'sales_info.grand_total', 0))} 
          icon={'https://pix.raceez.com/2020/12/09/icons8-check-dollar-80.png'} 
          backgroundColor={'-webkit-linear-gradient(90deg, #ee0979 0%, #ff6a00 100%)'}
        />
      </div>
      <Divider></Divider>
      
      <TicketsInfoTable ticketInfo={ticketInfo} summarySaleInfo={summarySaleInfo} />
      <OrderPendingTable orderPendingInfo={orderPendingInfo} />
      <TShirtSizeTable sizeInfo={sizeInfo} />
      <AttendantInfoTable attendantInfo={attendantInfo} />
      <GroupInfoTable groupInfo={groupInfo} getGroupInfo={getGroupInfo} eventId={eventIdQuery} id={eventData.selectedEventId} />

      <PermissionDeniedModal isOpen={isShowPermissionModal} toggle={() => setIsShowPermissionModal(!isShowPermissionModal)} />
      <TextModal 
        isOpen={isShowNoDataModal} 
        toggle={() => setIsShowNoDataModal(!isShowNoDataModal)} 
        bodyText={'No Data'}
      />
    </Layout>
  );
}
export default withRouter(Overview);
