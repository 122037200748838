import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Row, Col, Divider } from "antd"
import * as classes from './OrderDetail.module.css';
import orderAPI from "../../../api/OrderAPI/orderAPI";
import LoadingOverlay from 'react-loading-overlay';
import PacmanLoader from 'react-spinners/PacmanLoader'
import { getCurrentUser } from "../../../util/auth";
import { Modal, ModalBody } from "reactstrap";
import {
  BrowserView,
  MobileView,
} from "react-device-detect";
import Layout from "../../../components/Layout/Layout";
import { checkPermission } from "../../../util/helpers";
import { get } from "lodash";
class OrderDetail extends Component {
  state = {
    // spinner: true,
    detailOrder: null,
    order_product: [],
    customer: {},
    payment_method: {},
    count: 1,
    orderStatusModal: false,
  };

  user = getCurrentUser();
  isPermission = checkPermission(this.user, 'list');

  componentDidMount() {
    if (!this.isPermission) {
      this.props.history.push('/index')
    }

    const code = this.props.match.params.code;
    this.fetchDetailOrder(code)
  }

  fetchDetailOrder = async (code) => {
    try {
      const response = await orderAPI.getOrderDetail(code);
      this.setState({ detailOrder: response });
      // this.setState({ spinner: false });
    } catch (error) {
      this.setState({ isPermission: false });
      console.log("Faile to fetch", error);
    }
  };

  getOrderStatus = (value) => {
    switch (value) {
      case 'dat_hang_thanh_cong':
        return 1;
      case 'da_huy':
        return 3;
      default:
        return 2
    }
  };

  orderStatusModalToggle = () => this.setState({ orderStatusModal: !this.state.orderStatusModal });


  render() {

    return (
      <Layout>
        <MobileView style={{ overflowX: "scroll" }}>
          {this.state.detailOrder !== null ?
            <div>
              <div>
                <div>
                  <Row>
                    <Col className={classes.orderCode} span={24}>#{this.state.detailOrder.code}</Col>
                  </Row>
                  <Row>
                    <Col className={classes.groupName} span={24}>Thông tin khách hàng</Col>
                  </Row>
                  <Row className={classes.buyerInfo}>
                    <Col span={24}>
                      Họ và tên :
                      <span className={classes.buyerLabel}> {this.state.detailOrder.buyer.name} </span>
                    </Col>

                  </Row>
                  <Row className={classes.buyerInfo}>
                    <Col span={24}>
                      Email :
                      <span className={classes.buyerLabel}> {this.state.detailOrder.buyer.email} </span>
                    </Col>
                  </Row>
                  <Row className={classes.buyerInfo}>
                    <Col span={24}>
                      Phone :
                      <span className={classes.buyerLabel}>{get(this.state.detailOrder, 'buyer.phone_area_code', '') && `(+${get(this.state.detailOrder, 'buyer.phone_area_code', '')})`} {this.state.detailOrder.buyer.phone} </span>
                    </Col>
                  </Row>
                </div>
                <Divider></Divider>
                {this.state.detailOrder.tax.company_name !== "" ?
                  <div>
                    <Row>
                      <Col className={classes.groupName} span={24}>Thông tin hóa đơn</Col>
                    </Row>
                    <Row className={classes.buyerInfo}>
                      <Col span={24}>
                        Tên công ty :
                        <span className={classes.buyerLabel}> {this.state.detailOrder.tax.company_name} </span>
                      </Col>

                    </Row>
                    <Row className={classes.buyerInfo}>
                      <Col span={24}>
                        Tên người nhận :
                        <span className={classes.buyerLabel}> {this.state.detailOrder.tax.recipient_name} </span>
                      </Col>
                    </Row>
                    <Row className={classes.buyerInfo}>
                      <Col span={24}>
                        Địa chỉ công ty :
                        <span className={classes.buyerLabel}> {this.state.detailOrder.tax.company_address} </span>
                      </Col>

                    </Row>
                    <Row className={classes.buyerInfo}>
                      <Col span={24}>
                        Địa chỉ email:
                        <span className={classes.buyerLabel}> {this.state.detailOrder.tax.recipient_address} </span>
                      </Col>
                    </Row>
                    <Row className={classes.buyerInfo}>
                      <Col span={24}>
                        Mã số thuê công ty :
                        <span className={classes.buyerLabel}> {this.state.detailOrder.tax.company_tax_code} </span>
                      </Col>

                    </Row>
                    <Row className={classes.buyerInfo}>
                      <Col span={24}>
                        Số điện thoại người nhận :
                        <span className={classes.buyerLabel}> {this.state.detailOrder.tax.recipient_phone} </span>
                      </Col>
                    </Row>
                    <Divider></Divider>
                  </div> : null}
                <div>
                  <Row>
                    <Col className={classes.groupName} span={24}>Thông tin đơn hàng</Col>
                  </Row>
                  <Row className={classes.buyerInfo}>
                    <Col span={24}>
                      Giá trị đơn hàng :
                      <span className={classes.buyerLabel}> {this.state.detailOrder.grand_total.toLocaleString()} ₫ </span>
                    </Col>

                  </Row>
                  <Row className={classes.buyerInfo}>
                    <Col span={24}>
                      Thời gian mua hàng :
                      <span className={classes.buyerLabel}> {this.state.detailOrder.created_at} </span>
                    </Col>
                  </Row>
                  <Row className={classes.buyerInfo}>
                    <Col span={24}>
                      Trạng thái thanh toán :
                      <span className={classes.buyerLabel}> {this.state.detailOrder.payment_status_description_vi} </span>
                    </Col>
                  </Row>
                  <Row className={classes.buyerInfo}>
                    <Col span={24}>
                      Hình thức thanh toán :
                      <span className={classes.buyerLabel}> {this.state.detailOrder.transactions[0].payment_method_name_vi}</span>
                    </Col>
                  </Row>
                  <Row className={classes.buyerInfo}>
                    <Col span={24}>
                      Mã đơn hàng đối tác :
                      <span className={classes.buyerLabel}> {this.state.detailOrder.extra.vm_order_id}</span>
                    </Col>
                  </Row>
                  <Row className={classes.buyerInfo}>
                  </Row>
                  <Divider></Divider>
                </div>
                <div>
                  <Row>
                    <Col className={classes.groupName} span={24}>Thông tin sự kiện</Col>
                  </Row>
                  <Row className={classes.buyerInfo}>
                    <Col span={24}>
                      Tên sự kiện :
                      <span className={classes.buyerLabel}> {this.state.detailOrder.event.name_vi}  </span>
                    </Col>

                  </Row>
                  <Row className={classes.buyerInfo}>
                    <Col span={24}>
                      Đơn vị tổ chức :
                      <span className={classes.buyerLabel}> {this.state.detailOrder.merchant.name_vi} </span>
                    </Col>
                  </Row>
                  <Row className={classes.buyerInfo}>
                  </Row>
                </div>
              </div>
              <Divider></Divider>
              <Row>
                <Col className={classes.groupName} span={24}>Sản phẩm đã mua</Col>
              </Row>
              <table className="table table-hover">
                <tbody>
                  <tr className={classes.backgroundTr}>
                    <th scope="col" className={classes.backgroundTh}>#</th>
                    <th scope="col" className={classes.backgroundTh}>Tên sản phẩm</th>
                    <th scope="col" className={classes.backgroundTh}>Số lượng</th>
                    <th scope="col" className={classes.backgroundTh}>Giá sản phẩm</th>
                    <th scope="col" className={classes.backgroundTh}>Coupon</th>
                    <th scope="col" className={classes.backgroundTh}>Tổng tiền</th>
                  </tr>
                  {this.state.detailOrder.items.map((order, index) => {
                    return (
                      <tr key={order.product_id}>
                        <td className={classes.backgroundTd} >
                          <span>{index + 1}</span>
                        </td>
                        <td className={classes.backgroundTd}>
                          <span> {order.product_name_vi} </span>
                        </td>
                        <td className={classes.backgroundTd}>
                          <span> {order.quantity} </span>
                        </td>
                        <td className={classes.backgroundTd}>
                          <span> {order.product_price.toLocaleString()} ₫</span>
                        </td>
                        <td className={classes.backgroundTd}>
                          <span> {order.discount_amount.toLocaleString()} ₫</span>
                        </td>
                        <td className={classes.backgroundTd}>
                          <span> {order.grand_total.toLocaleString()} ₫</span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div> : null}
          {this.state.spinner ?
            <div style={{ position: 'absolute', top: '0', left: '0', margin: '0 auto', opacity: 0.5, width: '100%', height: '100%', backgroundColor: 'black' }}>
              <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                <LoadingOverlay
                  active={this.state.spinner}
                  spinner={
                    <PacmanLoader
                      color="#1794fc"
                      size="30px"
                    />
                  }
                >
                </LoadingOverlay>
              </div>
            </div> : null}
          <Modal isOpen={this.state.orderStatusModal} toggle={this.orderStatusModalToggle}>
            <ModalBody>Successful</ModalBody>
          </Modal>
        </MobileView>
        <BrowserView>
          {this.state.detailOrder !== null ?
            <div>
              <div>
                <div>
                  <Row>
                    <Col className={classes.orderCode} span={24}>#{this.state.detailOrder.code}</Col>
                  </Row>
                  <Row>
                    <Col className={classes.groupName} span={24}>Thông tin khách hàng</Col>
                  </Row>
                  <Row className={classes.buyerInfo}>
                    <Col span={12}>
                      Họ và tên :
                      <span className={classes.buyerLabel}> {this.state.detailOrder.buyer.name} </span>
                    </Col>
                    <Col span={12}>
                      Email :
                      <span className={classes.buyerLabel}> {this.state.detailOrder.buyer.email} </span>
                    </Col>
                  </Row>
                  <Row className={classes.buyerInfo}>
                    <Col span={12}>
                      Phone :
                      <span className={classes.buyerLabel}>{get(this.state.detailOrder, 'buyer.phone_area_code', '') && `(+${get(this.state.detailOrder, 'buyer.phone_area_code', '')})`} {this.state.detailOrder.buyer.phone} </span>
                    </Col>
                  </Row>
                </div>
                <Divider></Divider>
                {this.state.detailOrder.tax.company_name !== "" ?
                  <div>
                    <Row>
                      <Col className={classes.groupName} span={24}>Thông tin hóa đơn</Col>
                    </Row>
                    <Row className={classes.buyerInfo}>
                      <Col span={12}>
                        Tên công ty :
                        <span className={classes.buyerLabel}> {this.state.detailOrder.tax.company_name} </span>
                      </Col>
                      <Col span={12}>
                        Tên người nhận :
                        <span className={classes.buyerLabel}> {this.state.detailOrder.tax.recipient_name} </span>
                      </Col>
                    </Row>
                    <Row className={classes.buyerInfo}>
                      <Col span={12}>
                        Địa chỉ công ty :
                        <span className={classes.buyerLabel}> {this.state.detailOrder.tax.company_address} </span>
                      </Col>
                      <Col span={12}>
                        Địa chỉ email:
                        <span className={classes.buyerLabel}> {this.state.detailOrder.tax.recipient_address} </span>
                      </Col>
                    </Row>
                    <Row className={classes.buyerInfo}>
                      <Col span={12}>
                        Mã số thuê công ty :
                        <span className={classes.buyerLabel}> {this.state.detailOrder.tax.company_tax_code} </span>
                      </Col>
                      <Col span={12}>
                        Số điện thoại người nhận :
                        <span className={classes.buyerLabel}> {this.state.detailOrder.tax.recipient_phone} </span>
                      </Col>
                    </Row>
                    <Divider></Divider>
                  </div> : null}
                <div>
                  <Row>
                    <Col className={classes.groupName} span={24}>Thông tin đơn hàng</Col>
                  </Row>
                  <Row className={classes.buyerInfo}>
                    <Col span={12}>
                      Giá trị đơn hàng :
                      <span className={classes.buyerLabel}> {this.state.detailOrder.grand_total.toLocaleString()} ₫ </span>
                    </Col>
                    <Col span={12}>
                      Thời gian mua hàng :
                      <span className={classes.buyerLabel}> {this.state.detailOrder.created_at} </span>
                    </Col>
                  </Row>
                  <Row className={classes.buyerInfo}>
                    <Col span={12}>
                      Trạng thái thanh toán :
                      <span className={classes.buyerLabel}> {this.state.detailOrder.order_status_description_vi} </span>
                    </Col>
                  </Row>
                  <Row className={classes.buyerInfo}>
                    <Col span={12}>
                      Hình thức thanh toán :
                      <span className={classes.buyerLabel}> {this.state.detailOrder.transactions[0].payment_method_name_vi}</span>
                    </Col>

                    <Col span={12}>
                      Mã đơn hàng đối tác :
                      <span className={classes.buyerLabel}> {this.state.detailOrder.extra.vm_order_id}</span>
                    </Col>
                  </Row>
                  <Row className={classes.buyerInfo}>
                  </Row>
                  <Divider></Divider>
                </div>
                <div>
                  <Row>
                    <Col className={classes.groupName} span={24}>Thông tin sự kiện</Col>
                  </Row>
                  <Row className={classes.buyerInfo}>
                    <Col span={12}>
                      Tên sự kiện :
                      <span className={classes.buyerLabel}> {this.state.detailOrder.event.name_vi}  </span>
                    </Col>
                    <Col span={12}>
                      Đơn vị tổ chức :
                      <span className={classes.buyerLabel}> {this.state.detailOrder.merchant.name_vi} </span>
                    </Col>
                  </Row>
                  <Row className={classes.buyerInfo}>
                  </Row>
                </div>
              </div>
              <Divider></Divider>
              <Row>
                <Col className={classes.groupName} span={24}>Sản phẩm đã mua</Col>
              </Row>
              <table className="table table-hover">
                <tbody>
                  <tr className={classes.backgroundTr}>
                    <th scope="col" className={classes.backgroundTh}>#</th>
                    <th scope="col" className={classes.backgroundTh}>Tên sản phẩm</th>
                    <th scope="col" className={classes.backgroundTh}>Số lượng</th>
                    <th scope="col" className={classes.backgroundTh}>Giá sản phẩm</th>
                    <th scope="col" className={classes.backgroundTh}>Coupon</th>
                    <th scope="col" className={classes.backgroundTh}>Tổng tiền</th>
                  </tr>
                  {this.state.detailOrder.items.map((order, index) => {
                    return (
                      <tr key={order.product_id}>
                        <td className={classes.backgroundTd} >
                          <span>{index + 1}</span>
                        </td>
                        <td className={classes.backgroundTd}>
                          <span> {order.product_name_vi} </span>
                        </td>
                        <td className={classes.backgroundTd}>
                          <span> {order.quantity} </span>
                        </td>
                        <td className={classes.backgroundTd}>
                          <span> {order.product_price.toLocaleString()} ₫</span>
                        </td>
                        <td className={classes.backgroundTd}>
                          <span> {order.discount_amount.toLocaleString()} ₫</span>
                        </td>
                        <td className={classes.backgroundTd}>
                          <span> {order.grand_total.toLocaleString()} ₫</span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div> : null}
          {this.state.spinner ?
            <div style={{ position: 'absolute', top: '0', left: '0', margin: '0 auto', opacity: 0.5, width: '100%', height: '100%', backgroundColor: 'black' }}>
              <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                <LoadingOverlay
                  active={this.state.spinner}
                  spinner={
                    <PacmanLoader
                      color="#1794fc"
                      size="30px"
                    />
                  }
                >
                </LoadingOverlay>
              </div>
            </div> : null}
          <Modal isOpen={this.state.orderStatusModal} toggle={this.orderStatusModalToggle}>
            <ModalBody>Successful</ModalBody>
          </Modal>
        </BrowserView>
      </Layout>
    );
  }
}
export default withRouter(OrderDetail);
