import { get, isEmpty } from 'lodash';
import React from 'react';
import { formatNumber } from '../../../util/helpers';

const TShirtSizeTable = ({ sizeInfo }) => {
  return !isEmpty(sizeInfo) ? (
    <div style={{ width: "100%" }}>
      <h3>
        T-shirt-size info
      </h3>
      <table className="table table-hover mw-100" style={{width: 500, tableLayout: 'fixed'}}>
        <tbody>
          <tr style={{ backgroundColor: '#f5f5f5' }}>
            <th scope="col">Size</th>
            <th scope="col">Quantity</th>
          </tr>
          {get(sizeInfo, 't_shirt_size_info', []).map((size, index) => (
            <tr key={index}>
              <td style={{textTransform: 'uppercase', fontWeight: '600'}}>{size.size_name_en}</td>
              <td>{formatNumber(size.quantity)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : null
}

export default TShirtSizeTable;