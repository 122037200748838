import axiosClient from "../axiosClient";

const orderAPI = {
  getOrders: (page, eventId, filter) => {
    const url = `/orders/page/${page}?order_code=${filter.order_code}&buyer_phone=${filter.buyer_phone}&buyer_name=${filter.buyer_name}&buyer_email=${filter.buyer_email}&event_id=${eventId}&payment_method=${filter.payment_method}&is_vat=${filter.is_vat}&order_status=${filter.order_status}&from_date=${filter.from_date}&to_date=${filter.to_date}`;
    return axiosClient.get(url, page);
  },
  getOrderDetail: (code) => {
    const url = `/orders/${code}`;
    return axiosClient.get(url, code);
  },
  getCouponCodesUsed: (id) => {
    const url = `/events/${id}/coupon-codes-used`;
    return axiosClient.get(url);
  },
  getPublicOrdersExportedData: (eventId, filter) => {
    const url = `/public-orders/events/${eventId}/export?order_code=${filter.order_code}&buyer_phone=${filter.buyer_phone}&buyer_name=${filter.buyer_name}&buyer_email=${filter.buyer_email}&event_id=${eventId}&payment_method=${filter.payment_method}&is_vat=${filter.is_vat}&order_status=${filter.order_status}&from_date=${filter.from_date}&to_date=${filter.to_date}`;
    return axiosClient.get(url);
  },
};

export default orderAPI;
