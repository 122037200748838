import axiosClient from "../axiosClient";

const couponAPI = {
  getCouponCodes: (page, params, pageSize = 20) => {
    const url = `/coupon-code/page/${page}${params}&limit=${pageSize}`;
    return axiosClient.get(url);
  },
  getCouponCodeExportData: (params) => {
    const url = `/coupon-code/export${params}`;
    return axiosClient.get(url);
  },
};

export default couponAPI;
