import React from 'react';
import { getCurrentUser, logout } from '../../../util/auth';
import { Layout } from 'antd';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';

const { Header } = Layout;

const DesktopHeader = (props) => {
  const user = getCurrentUser();

  const handleLogout = () => {
    logout();
    props.history.push("/login");
  };

  const handleEdit = () => {  
    props.history.push("/profile/rename");
  };

  return (
    <Header className="site-layout-background" style={{ padding: 0 }}>
      <div style={{float: 'right', maxHeight: '100%', position: 'relative'}}>
        <ul className="navbar-nav" style={{flex: 1, marginRight: '16px'}}>
          <li className="nav-item dropdown">
            <div 
              className="nav-link dropdown-toggle" 
              id="navbarDropdownMenuLink" 
              role="button" data-toggle="dropdown" 
              aria-haspopup="true" 
              aria-expanded="false"
              style={{maxWidth: '100%', marginTop:-7}}>
              <img alt="" src={get(user, 'result.avatar', '')} style={{maxWidth: '40px', height: 'auto', marginRight: '12px', textTransform: 'capitalize'}} className="rounded-circle" />
                {get(user, 'result.full_name', '')}
            </div>
            <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            
              <button onClick={handleLogout} className="dropdown-item" style={{lineHeight: 1.5}} >Log Out</button> 
              <button onClick={handleEdit} className="dropdown-item" style={{lineHeight: 1.5}} >Edit Profile</button> 
            </div>
          </li>   
        </ul>
      </div>
    </Header>

  );
}

export default withRouter(DesktopHeader);