import React, { } from "react";
import "./App.css";
import { Route, Switch, Redirect } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Login from "./pages/Login";
import Login from "./pages/Login/Login";
import Users from "./pages/Users/Users";
import Orders from "./pages/Orders/Orders";
import Overview from "./pages/Overview/Overview";
import Attendants from "./pages/Attendants/Attendants";
import OrderDetail from "./pages/Orders/OrderDetail/OrderDetail";
import CreateUser from "./pages/Users/CreateUser/CreateUser";
import EditUser from "./pages/Users/EditUser/EditUser";
import Index from "./pages/Index";
import ChangePassword from "./pages/Profile/ChangePassword/ChangePassword"
import Rename from "./pages/Profile/Rename/Rename"

import { ToastContainer } from "react-toastify";
import CouponCodes from "./pages/CouponCodes/CouponCodes";
import Slider from "react-slick";
import { get, isEmpty } from "lodash";
import { useState } from "react";
import { useEffect } from "react";
import salesAPI from "./api/SalesAPI/salesAPI";
import { isMobile } from "react-device-detect";
import { getCurrentUser } from "./util/auth";

const settings = {
  slidesToShow: 1,
  swipeToSlide: true,
  focusOnSelect: true,
  autoplay: true,
  className: 'slider',
  dots: false,
  dotsClass: 'slick-dots slick-thumb dots',
  swipe: false,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', right: 12, zIndex: 7 }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        left: 12,
        zIndex: 7,
      }}
      onClick={onClick}
    />
  );
}

function App() {
  const [banners, setBanners] = useState([]);

  const user = getCurrentUser();

  useEffect(() => {
    if (!isEmpty(user)) {
      getBanners();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
  const getBanners = async () => {
    try {
      const response = await salesAPI.getCommercialBanner();
      setBanners(response);
    } catch (error) {
      console.log("Faile to fetch", error);
    }
  };

  return (
    <>
      <div>
        <Slider {...settings}>
          {!isEmpty(user) ? banners.map((banner, index) => (
            <>
              {!isEmpty(get(banner, 'open_url', '')) ? (
                <a href={get(banner, 'open_url', '#')} target="_blank" rel="noopener noreferrer" style={{display: 'block'}}>
                  <img src={get(banner, `${isMobile ? "mobile_banner_url" : "desktop_banner_url"}`, '#')} key={index} alt="banner" style={{maxWidth: '100%', height: 'auto', verticalAlign: 'bottom'}} />
                </a>
              ) : (
                <img src={get(banner, `${isMobile ? "mobile_banner_url" : "desktop_banner_url"}`, '#')} key={index} alt="banner" style={{maxWidth: '100%', height: 'auto', verticalAlign: 'bottom'}} />
              )}
            </>
          )) : <></>}
        </Slider>
      </div>
      <Switch>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route path="/index">
          <Index />
        </Route>
        {/* <Route path="/login">
          <Login />
        </Route> */}
        <Route exact path="/overview">
          <Overview />
        </Route>
        <Route exact path="/attendants">
          <Attendants />
        </Route>
        <Route exact path="/coupon-codes">
          <CouponCodes />
        </Route>
        <Route exact path="/profile/password">
          <ChangePassword />
        </Route>
        <Route exact path="/profile/rename">
          <Rename />
        </Route>
        <Route path="/orders/:code">
          <OrderDetail />
        </Route>
        <Route exact path="/users">
          <Users />
        </Route>
        <Route path="/users/create">
          <CreateUser />
        </Route>
        <Route  path="/users/:id">
          <EditUser />
        </Route>
        <Route exact path="/">
          <Overview />
          <Redirect to="/overview" />
        </Route>
        <Route exact path="/orders">
          <Orders />
          <Redirect to="/orders" />
        </Route>

        <Redirect to="/" />
      </Switch>
      <ToastContainer />
    </>
  );
}

export default App;
