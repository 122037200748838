import { get, isEmpty } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useState, useEffect } from 'react';
import Pagination from 'react-js-pagination';
import { formatNumber, paginate } from '../../../util/helpers';
import styles from "./GroupInfoTable.module.css";
import { Select, DatePicker } from "antd";

const DEFAULT_PAGE_SIZE = 10;

const GroupInfoTable = ({ groupInfo, getGroupInfo, eventId, id }) => {
  const [groupInfoPageNumber, setGroupInfoPageNumber] = useState(1);
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const [q, setQ] = useState('');
  const [status, setStatus] = useState('');

  const [dateRange, setDateRange] = useState({
    gte: '',
    lte: ''
  });

  useEffect(() => {
    setQ('');
    setStatus('');
    setDateRange({
      gte: '',
      lte: ''
    })
  }, [id]);

  const _handleGroupInfoPagination = (page) => {
    setGroupInfoPageNumber(page);
  }

  const handleSearchGroup = () => {
    getGroupInfo(eventId, dateRange.gte, dateRange.lte, q, status);
  }

  return !isEmpty(groupInfo) ? (
    <div style={{ width: "100%" }}>
      <h3>
        Group Info
      </h3>
      <table className="table table-hover mw-100" style={{ width: 500, tableLayout: 'fixed' }}>
        <tbody>
          <tr style={{ backgroundColor: '#f5f5f5' }}>
            <th scope="col"></th>
            <th scope="col">Total</th>
            <th scope="col">Total Paid</th>
          </tr>
          <tr>
            <td style={{ fontWeight: '600' }}>Group</td>
            <td>{formatNumber(get(groupInfo, 'total_group', 0))}</td>
            <td>{formatNumber(get(groupInfo, 'total_paid_group', 0))}</td>
          </tr>
          <tr>
            <td style={{ fontWeight: '600' }}>Member</td>
            <td>{formatNumber(get(groupInfo, 'total_member', 0))}</td>
            <td>{formatNumber(get(groupInfo, 'total_paid_member', 0))}</td>
          </tr>
        </tbody>
      </table>

      <div className={styles['filter-group']}>
        <input placeholder="Search by group name" value={q} className={styles['input-search']} onChange={(e) => setQ(e.target.value)} />

        <Select
          showSearch
          value={status}
          className={styles['select-status']}
          style={{ padding: 0 }}
          onChange={(e) => setStatus(e)}
          filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
          optionFilterProp={(optionA, optionB) =>
            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
          }
        >
          <Option value="">
            All group status
          </Option>
          <Option value="registered">
            Opening
          </Option>
          <Option value="wait_for_paid">
            Payment request submitted
          </Option>
          <Option value="success">
            Success
          </Option>
          <Option value="cancel">
            Cancel
          </Option>
        </Select>

        <RangePicker
          className="mb-3 mr-3"
          style={{ width: 300, marginTop: 16 }}
          onChange={(e) => {
            const gte = moment(e[0]).format('YYYY-MM-DD');
            const lte = moment(e[1]).format('YYYY-MM-DD');

            const date = {
              gte: gte,
              lte: lte
            };

            setDateRange({...date});
          }}
          bordered={true}
          value={
            [
              (isEmpty(dateRange.gte) && isEmpty(dateRange.lte))
                ? ""
                : moment(dateRange.lte), (dateRange.gte === "") ? "" : moment(dateRange.gte)]}
        />

        <div className={styles['btn-search']} onClick={handleSearchGroup}>Search</div>

      </div>

      {!isEmpty(get(groupInfo, 'statistical_data', [])) ? (
        <>
          <div className='table-responsive'>
            <table className="table table-hover" style={{ width: '100%', tableLayout: 'fixed' }}>
              <colgroup>
                <col span="1" style={{ width: '16.6666667%', minWidth: 150 }} />
                <col span="1" style={{ width: '16.6666667%', minWidth: 150 }} />
                <col span="1" style={{ width: '16.6666667%', minWidth: 150 }} />
                <col span="1" style={{ width: '16.6666667%', minWidth: 150 }} />
                <col span="1" style={{ width: '16.6666667%', minWidth: 150 }} />
                <col span="1" style={{ width: '16.6666667%', minWidth: 150 }} />
              </colgroup>
              <tbody>
                <tr style={{ backgroundColor: '#f5f5f5' }}>
                  <th scope="col">STT</th>
                  <th scope="col">Group name</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Paid</th>
                  <th scope="col">Closed date</th>
                  <th scope="col">Status</th>
                </tr>
                {paginate(get(groupInfo, 'statistical_data', []), groupInfoPageNumber, DEFAULT_PAGE_SIZE,).map((group, index) => (
                  <tr key={index}>
                    <td>{(groupInfoPageNumber - 1) * DEFAULT_PAGE_SIZE + index + 1}</td>
                    <td style={{ fontWeight: '600' }}>{get(group, 'name', '')}</td>
                    <td>{formatNumber(get(group, 'attendant_quantity', 0))}</td>
                    <td>{formatNumber(get(group, 'attendant_paid_quantity', 0))}</td>
                    <td>{moment(group?.expired_date).format('DD/MM/YYYY | HH:mm:ss')}</td>
                    <td style={{ textTransform: 'capitalize' }}>{get(group, 'payment_status', '')}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='float-right'>
            {get(groupInfo, 'statistical_data', []).length >= DEFAULT_PAGE_SIZE + 1 && (
              <Pagination
                pageRangeDisplayed={7}
                activePage={groupInfoPageNumber}
                itemsCountPerPage={DEFAULT_PAGE_SIZE}
                totalItemsCount={get(groupInfo, 'statistical_data', []).length}
                onChange={(pageNumber) => _handleGroupInfoPagination(pageNumber)}
                itemClass="page-item"
                linkClass="page-link"
                style={{
                  padding: 20,
                  textAlign: 'right',
                  width: '100%',
                }}
              />
            )}
          </div>
        </>
      ) : null}
    </div>
  ) : null;
}

export default GroupInfoTable;