import React, { useState } from "react";
import {
  withRouter,
} from "react-router-dom";
import 'antd/dist/antd.css'; 

import { isLoggedIn } from "../../util/auth";
import {
  isMobile,
} from "react-device-detect";

import SideBar from "./desktop/Sidebar";

import DesktopHeader from "./desktop/Header";
import MobileHeader from "./mobile/Header";

import { Layout } from 'antd';

const { Content } = Layout;

const DekstopLayout = ({ children }) => {
  const [collapsed] = useState(false)

  return (
    <Layout style={{minHeight:'100vh'}}>
      <SideBar toggledCollapse = {collapsed} />
      <Layout className="site-layout">
        <DesktopHeader />
        <Content
          className="site-layout-background"
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  )
}

const MobileLayout = ({ children }) => {

  return (
      <Layout style={{minHeight:'100vh'}}>
        <MobileHeader />
        <Layout className="site-layout">
          <Content
            className="site-layout-background"
            style={{
              margin: '24px 16px',
              padding: 24,
              minHeight: 280,
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    )
  }

const MainLayout = (props) => {
  const { children } = props;

  if (!isLoggedIn()) props.history.push("/login");
  
  return isMobile ? <MobileLayout children={children} /> : <DekstopLayout children={children} />;
}

export default withRouter(MainLayout);
