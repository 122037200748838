import { get } from 'lodash';
import React from 'react';
import { isMobile } from 'react-device-detect';
import Card from '../../../components/Card/Card';
import { currencyFormat, formatNumber } from '../../../util/helpers';
import * as classes from "../OverviewTable.module.css";

const Revenue = ({ summarySaleInfo }) => {
  return (
    <>
      <tr
        style={{ fontSize: "14px" }}
      >
        <td className={classes.backgroundTd} style={{ border: "0" }}>
          <span style={{ fontWeight: "bold" }}>
            {/* Blank */}
          </span>
        </td>
        <td className={classes.backgroundTd} style={{ border: "0" }}>
          <span style={{ fontWeight: "bold" }}>
            {/* Blank */}
          </span>
        </td>

        <td className={classes.backgroundTd} style={{ border: 0, borderRight: '1px solid #f0f0f0' }}>
          <span className={classes.spanTable}>
            {/* Blank */}
          </span>
        </td>
        <td className={classes.backgroundTd}>
          <span style={{ float: "right", fontWeight: "bold" }}>
            Revenue before discount 
          </span>
        </td>
        <td className={classes.backgroundTd}>
          <span className={classes.spanTable}>
            {currencyFormat(get(summarySaleInfo, 'sales_info.sub_total', 0))}
          </span>
        </td>
      </tr>
      <tr
        style={{ fontSize: "14px" }}
      >
        <td className={classes.backgroundTd} style={{ border: "0" }}>
          <span style={{ fontWeight: "bold" }}>
            {/* Blank */}
          </span>
        </td>
        <td className={classes.backgroundTd} style={{ border: "0" }}>
          <span style={{ fontWeight: "bold" }}>
            {/* Blank */}
          </span>
        </td>
        <td className={classes.backgroundTd} style={{ border: 0, borderRight: '1px solid #f0f0f0' }}>
          <span className={classes.spanTable}>
            {/* Blank */}
          </span>
        </td>
        <td className={classes.backgroundTd}>
          <span style={{ float: "right", fontWeight: "bold" }}>
            Total discount coupon:{" "}
          </span>
        </td>
        <td className={classes.backgroundTd}>
          <span className={classes.spanTable}>
            {currencyFormat(get(summarySaleInfo, 'sales_info.discount_coupon', 0))}
          </span>
        </td>
      </tr>
      <tr
        style={{ fontSize: "14px" }}
      >
        <td className={classes.backgroundTd} style={{ border: "0" }}>
          <span style={{ fontWeight: "bold" }}>
            {/* Blank */}
          </span>
        </td>
        <td className={classes.backgroundTd} style={{ border: "0" }}>
          <span style={{ fontWeight: "bold" }}>
            {/* Blank */}
          </span>
        </td>
        <td className={classes.backgroundTd} style={{ border: 0, borderRight: '1px solid #f0f0f0' }}>
          <span className={classes.spanTable}>
            {/* Blank */}
          </span>
        </td>
        <td className={classes.backgroundTd}>
          <span style={{ float: "right", fontWeight: "bold" }}>
            Total discount promotion:
          </span>
        </td>
        <td className={classes.backgroundTd}>
          <span className={classes.spanTable}>
            {currencyFormat(get(summarySaleInfo, 'sales_info.discount_promotion', 0))}
          </span>
        </td>
      </tr>
      <tr
        style={{ fontSize: "14px" }}
      >
        <td className={classes.backgroundTd} style={{ border: "0" }}>
          <span style={{ fontWeight: "bold" }}>
            {/* Blank */}
          </span>
        </td>
        <td className={classes.backgroundTd} style={{ border: "0" }}>
          <span style={{ fontWeight: "bold" }}>
            {/* Blank */}
          </span>
        </td>
        <td className={classes.backgroundTd} style={{ border: 0, borderRight: '1px solid #f0f0f0' }}>
          <span className={classes.spanTable}>
            {/* Blank */}
          </span>
        </td>
        <td className={classes.backgroundTd}>
          <span style={{ float: "right", fontWeight: "bold" }}>
            Revenue after discount:
          </span>
        </td>
        <td className={classes.backgroundTd}>
          <span className={classes.spanTable}>
            {currencyFormat(get(summarySaleInfo, 'sales_info.grand_total', 0))}
          </span>
        </td>
      </tr>
    </>
  )
}

const MobileRevenue = ({ summarySaleInfo }) => {
  return (
    <Card
      backgroundColor={"-webkit-linear-gradient(90deg, #ee0979 0%, #ff6a00 100%)"}
      className="row p-3 w-100 mx-0 my-2"
      style={{
        marginBottom: "10px",
        borderRadius: "10px",
      }}
    >
      <div className="column" style={{ width: "100%" }}>
        <h2
          style={{ color: "white", fontWeight: "bold" }}
          className="number"
        >
          Summary Revenue
        </h2>
      </div>
      <div
        className="column"
        style={{ width: "62%", paddingTop: "5px" }}
      >
        <span
          style={{
            color: "white",
            fontSize: "12px",
          }}
        >
          $Revenue before discount:{" "}
        </span>{" "}
        <br />
        <span
          style={{
            color: "white",
            fontSize: "12px",
          }}
        >
          $Total discount coupon:{" "}
        </span>{" "}
        <br />
        <span
          style={{
            color: "white",
            fontSize: "12px",
          }}
        >
          $Total discount promotion:{" "}
        </span>{" "}
        <br />
        <span
          style={{
            color: "white",
            fontSize: "12px",
            fontWeight: "bold",
            fontFamily:
              "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
          }}
        >
          $Revenue after discount:{" "}
        </span>
      </div>
      <div
        className="column"
        style={{ width: "38%", paddingTop: "5px" }}
      >
        <span
          style={{
            color: "white",
            fontSize: "14px",
          }}
        >
          {currencyFormat(get(summarySaleInfo, 'sales_info.sub_total', 0))}
        </span>{" "}
        <br />
        <span
          style={{
            color: "white",
            fontSize: "14px",
          }}
        >
          {currencyFormat(get(summarySaleInfo, 'sales_info.discount_coupon', 0))}
        </span>{" "}
        <br />
        <span
          style={{
            color: "white",
            fontSize: "14px",
          }}
        >
          {currencyFormat(get(summarySaleInfo, 'sales_info.discount_promotion', 0))}
        </span>
        <br />
        <span
          style={{
            color: "white",
            fontSize: "14px",
            fontWeight: "bold",
          }}
        >
          {currencyFormat(get(summarySaleInfo, 'sales_info.grand_total', 0))}
        </span>
      </div>
    </Card>

  )
}

const TicketsInfoTable = ({ ticketInfo, summarySaleInfo }) => {
  return get(ticketInfo, 'ticket_quantity_info', null) ? (
    <React.Fragment>
      <h3 className="pl-0">
        Tickets & Revenue
      </h3>
      <div className='table-responsive'>
        <table className="table table-hover" style={{tableLayout: "fixed;"}}>
        <colgroup>
        <col span="1" style={{width: '20%', minWidth: 150}} />
         <col span="1" style={{width: '20%', minWidth: 150}} />
         <col span="1" style={{width: '20%', minWidth: 150}} />
         <col span="1" style={{width: '20%', minWidth: 150}} />
         <col span="1" style={{width: '20%', minWidth: 150}} />
        </colgroup>
          <tbody>
            <tr style={{backgroundColor: '#f0f0f0'}} >
              <th scope="col" className='text-center'>
                Ticket Phase
              </th>
              <th scope="col" className='text-center'>
                Ticket Name
              </th>
              <th scope="col" className='text-center'>
                Price
              </th>
              <th scope="col" className='text-center'>
                Order Success
              </th>
              <th scope="col" className='text-center'>
                Total
              </th>
            </tr>

            {get(ticketInfo, 'ticket_quantity_info', []).map(
              (ticket, index) => {
                return (
                  <tr
                    key={index}
                    style={{ fontSize: "14px" }}
                  >
                    <td className={classes.backgroundTd}>
                      <span style={{ fontWeight: "bold" }}>
                        {get(ticket, 'ticket_phase_name_en', '')}
                      </span>
                    </td>

                    <td className={classes.backgroundTd}>
                      <span style={{ fontWeight: "bold" }}>
                        {get(ticket, 'ticket_name_en', '')}
                      </span>
                    </td>
                    <td className={classes.backgroundTd}>
                      <span className={classes.spanTable}>
                        {currencyFormat(get(ticket, 'product_price', 0))}
                      </span>
                    </td>
                    <td className={classes.backgroundTd}>
                      <span style={{ float: "right", fontWeight: "bold" }}>
                        {formatNumber(get(ticket, 'quantity', 0))}
                      </span>
                    </td>
                    <td className={classes.backgroundTd}>
                      <span className={classes.spanTable}>
                        {currencyFormat(get(ticket, 'product_price', 0) * get(ticket, 'quantity', 0))}
                      </span>
                    </td>
                  </tr>
                );
              }
            )}
            {!isMobile && <Revenue summarySaleInfo={summarySaleInfo} />}
          </tbody>
        </table>
      </div>
      {isMobile && <MobileRevenue summarySaleInfo={summarySaleInfo} />}
    </React.Fragment>
  ) : null;
}

export default TicketsInfoTable;