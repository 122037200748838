import axiosClient from "../axiosClient";

const merchantAPI = {
  getMerchants: () => {
    const url = `/merchants`;
    return axiosClient.get(url);
  },
};

export default merchantAPI;
