import React from 'react';
import Card from '../../../components/Card/Card';
import * as classes from "./SummaryCard.module.css";

const SummaryCard = ({ label, value, icon, backgroundColor }) => {
  return (
    <Card backgroundColor={backgroundColor} style={{marginRight: 16, width: 250, height: 140, borderRadius: 10}} className="mb-3">
      <div className={`d-flex flex-column p-2 mb-2 mr-3 position-relative w-100 h-100`}>
        <h3 className="text-light font-weight-bold my-0 ml-2" style={{fontSize: 24}}>
          {value}
        </h3>
        <div>
          <span className="text-light text-uppercase ml-2" style={{fontSize: 12}}>
            {label}
          </span>
        </div>
        <div className={classes.IconWrapper}>
          <img className={`${classes.Icon}`}
            src={icon}
            alt={label}
          />
        </div>
      </div>
      
    </Card>
  )
}

export default SummaryCard;