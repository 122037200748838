import axiosClient from "../axiosClient";

const salesAPI = {
  getSummarySales: (fromDate = '', toDate = '') => {
    const url = `/sales/all?from_date=${fromDate}&to_date=${toDate}`;
    return axiosClient.get(url);
  },
  getSummarySalesByEvent: (eventId, fromDate, toDate) => {
    const url = `/sales/event/${eventId}?from_date=${fromDate}&to_date=${toDate}`;
    return axiosClient.get(url);
  },
  getAttendantExportDataByEvent: (eventId) => {
    const url = `/sales/event/${eventId}/attendants/export`;
    return axiosClient.get(url);
  },
  getAttendantsByEvent: (eventId, filter, page) => {
    const url = `/sales/event/${eventId}/attendants/page/${page}?id_card=${filter.id_card}&phone=${filter.phone}&email=${filter.email}&category=${filter.category}`;
    return axiosClient.get(url);
  },
  getAttendantInfoByEvent: (eventId, fromDate = '', toDate = '') => {
    const url = `/sales/event/${eventId}/attendant/quantity?from_date=${fromDate}&to_date=${toDate}`;
    return axiosClient.get(url);
  },
  getSizeInfoByEvent: (eventId, fromDate = '', toDate = '') => {
    const url = `/sales/event/${eventId}/t-shirt-size/quantity?from_date=${fromDate}&to_date=${toDate}`;
    return axiosClient.get(url);
  },
  getTicketInfoByEvent: (eventId, fromDate = '', toDate = '') => {
    const url = `/sales/event/${eventId}/ticket/quantity?from_date=${fromDate}&to_date=${toDate}`;
    return axiosClient.get(url);
  },
  getCommercialBanner: () => {
    const url = `/commercial?type=partner&position=top`;
    return axiosClient.get(url);
  },

  // getSummarySalesByMerchant: (merchantId) => {
  //   const url = `/sales/merchant/${merchantId}`;
  //   return axiosClient.get(url);
  // },
};

export default salesAPI;
