import { get } from 'lodash';
import React from 'react';
import { withRouter } from 'react-router-dom';
import logo from "../../../images/actiup-white.png";
import { getCurrentUser, logout } from '../../../util/auth';

const MobileHeader = (props) => {
  const user = getCurrentUser();
  const handleLogout = () => {
    logout();
    props.history.push("/login");
  };

  return (
    <div>
      <nav className="navbar navbar-expand-md bg-dark navbar-dark">
        <img src={logo} style={{maxWidth: '150px', padding: '10px', height: 60}} alt="" />
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="collapsibleNavbar" >
          <ul className="navbar-nav">
            <li className="nav-item" style={{textAlign: 'center', margin: '10px 0 10px 0'}}>
              <img src={get(user, 'result.avatar', '')} style={{maxWidth: '30px', height: 'auto', marginBottom:5}} className="rounded-circle" alt=""/>
              <a  style={{fontSize: '22px', fontWeight: 'bold', color: 'white', margin: '0 30px 0 30px'}} href="/profile/rename" >{get(user, 'result.full_name', '')}</a>
              <img onClick={handleLogout} src="https://pix.raceez.com/2020/12/16/icons8-export-26_20201216031404.png" style={{maxWidth: '30px', marginBottom:5}} alt="" />
            </li>
            <li className="nav-item" style={{textAlign: 'center', fontWeight: '50px'}}>
              <a  style={{fontSize: '20px', fontWeight: 'bold', color: 'white'}} className="nav-link" href="/overview">Overview</a>
            </li>
            <li className="nav-item" style={{textAlign: 'center'}}>
              <a style={{fontSize: '20px', fontWeight: 'bold', color: 'white'}} className="nav-link" href="/attendants">Attendants</a>
            </li>
            <li className="nav-item" style={{textAlign: 'center'}}>
              <a style={{fontSize: '20px', fontWeight: 'bold', color: 'white'}} className="nav-link" href="/orders">List order</a>
            </li>
            <li className="nav-item" style={{textAlign: 'center'}}>
              <a style={{fontSize: '20px', fontWeight: 'bold', color: 'white'}} className="nav-link" href="/coupon-codes">Coupon Codes</a>
            </li>
            <li className="nav-item" style={{textAlign: 'center'}}>
              <a style={{fontSize: '20px', fontWeight: 'bold', color: 'white'}} className="nav-link" href="/users">User</a>
            </li>
          </ul>
        </div>
      </nav>
    </div>

  )
}

export default withRouter(MobileHeader);