import React from "react";
import { withRouter } from 'react-router';
import { Layout, Menu } from 'antd';
import logo from "../../../images/actiup-white.png";
import {
  UserOutlined,
} from '@ant-design/icons';
import { getCurrentUser } from "../../../util/auth";
import { checkPermission } from "../../../util/helpers";

const { Sider } = Layout;

const Sidebar = (props) => {
  const user = getCurrentUser();

  function isPermission(object) {
    if (checkPermission(user, object)) return true;
    return false;
  }

  function getDefaultSelectedKeys() {
    const path = props.location.pathname;
    switch (path) {
      case '/':
        return ['0'];
      case '/overview':
        return ['1'];
      case '/attendants':
        return ['2'];
      case '/orders':
        return ['3'];
      case '/users':
        return ['4'];
      case '/coupon-codes':
        return ['5'];
      default: return;
    }
  }

  return (
    <Sider>
      <div
        style={{cursor: 'pointer', textAlign: 'center'}} 
        onClick={() => {props.history.push('/overview')}}
      > 
        <img src={logo} style={{maxWidth: '100%', height: 60, padding: '10px'}} alt="" />
      </div>
      <Menu theme="dark" mode="inline" defaultSelectedKeys={getDefaultSelectedKeys()}>
        {isPermission('overview') ? (
          <Menu.Item 
            key="1" 
            icon={<UserOutlined />}
            onClick={() => {props.history.push('/overview')}}
          >
            Overview
          </Menu.Item>
        ) : null}
        {isPermission('attendant') ? (
          <Menu.Item 
            key="2" 
            icon={<UserOutlined />}
            onClick={() => {props.history.push('/attendants')}}
          >
            Attendants
          </Menu.Item>
        ): null}
        {isPermission('list') ? (
          <Menu.Item 
            key="3" 
            icon={<UserOutlined />}
            onClick={() => {props.history.push('/orders')}}
          >
            Orders
          </Menu.Item>
        ) : null}
        {isPermission('coupon-codes') ? (
          <Menu.Item 
            key="5" 
            icon={<UserOutlined />}
            onClick={() => {props.history.push('/coupon-codes')}}
          >
            Coupon Codes
          </Menu.Item>
        ) : null}
        {isPermission('user') ? (
          <Menu.Item 
            key="4" 
            icon={<UserOutlined />}
            onClick={() => {props.history.push('/users')}}
          >
            Users
          </Menu.Item>
        ) : null}
      </Menu>
    </Sider>
  );
}

export default withRouter(Sidebar);
