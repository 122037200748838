import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { getCurrentUser, logout } from "../../../util/auth";
import adminUserAPI from "../../../api/AdminUserAPI/adminUserAPI";
import { Menu } from "antd";
import { BrowserView, MobileView } from "react-device-detect";
import { toast } from 'react-toastify'
import Layout from "../../../components/Layout/Layout";

class ChangePassword extends Component {
  state = {
    id: -1,
    password: "",
    new_password: "",
  };
  id = "";
  user = getCurrentUser();
  componentDidMount() {}

  fetchChangePassword = async () => {
    try {
      const params = {
        password: this.state.password,
        new_password: this.state.new_password,
      };
      if (!this.state.password) {
        return toast.warn('Please fill in your password.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      }
      if (!this.state.new_password) {
        return toast.warn('Please fill in your new paosword.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      }

      const response = await adminUserAPI.changePassword(
        params,
        this.user.result.id
      );
      console.log(response);
      if (response) {
        toast.success('Password Changed Successfully!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        })
        this.setState({
          new_password: response.new_password,
          password: response.password,
        });
        this.handleLogout()
      }
    } catch (error) {
      const errorMessage = error.response.data.error?.message || 'Error has occurred'
      toast.error(errorMessage, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      })

      console.log("Faile to fetch", error);
    }
  };

  handleFormChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleSave = async () => {
    await this.fetchChangePassword();
  };

  handleLogout = () => {
    logout();
    this.props.history.replace("/login");
  };

  render() {
    return (
      <Layout>
        <MobileView>
        <Menu theme="white" mode="horizontal" defaultSelectedKeys={["0"]}>
          <Menu.Item
            style={{  }}
            key="1"
            onClick={() => {
              this.props.history.push("/profile/rename");
            }}
          >
            Profile
          </Menu.Item>
          <Menu.Item
            style={{  }}
            key="2"
            onClick={() => {
              this.props.history.push("/profile/password");
            }}
          >
            Change Password
          </Menu.Item>
        </Menu>

        <Form
          style={{ width: "50%", marginTop: 60, marginLeft:'auto', marginRight:'auto' , textAlign: "webkit-center" }}
        >
          <FormGroup row>
            <Label for="exampleEmail" sm={2}>
              Password
            </Label>
            <Col sm={20}>
              <Input
                id="password"
                name="password"
                type="password"
                placeholder="Enter Password"
                className="form-control"
                value={this.user.result.password}
                onChange={this.handleFormChange}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="exampleEmail" sm={2}>
              New Password
            </Label>
            <Col sm={20}>
              <Input
                id="new_password"
                name="new_password"
                type="password"
                placeholder="Enter New Password"
                value={this.state.new_password}
                onChange={this.handleFormChange}
              />
            </Col>
          </FormGroup>

          <FormGroup check row>
            <Col sm={{ size: 10, offset: 2 }}>
              <Button onClick={this.handleSave.bind(this)}>Submit</Button>
            </Col>
          </FormGroup>
        </Form>
        </MobileView>
        <BrowserView>
        <Menu theme="white" mode="horizontal" defaultSelectedKeys={["0"]}>
          <Menu.Item
            style={{ fontSize: "large" }}
            key="1"
            onClick={() => {
              this.props.history.push("/profile/rename");
            }}
          >
            Profile
          </Menu.Item>
          <Menu.Item
            style={{ fontSize: "large" }}
            key="2"
            onClick={() => {
              this.props.history.push("/profile/password");
            }}
          >
            Change Password
          </Menu.Item>
        </Menu>

        <Form
          style={{ width: "50%", marginTop: 60, marginLeft:'auto', marginRight:'auto' , textAlign: "webkit-center" }}
        >
          <FormGroup row>
            <Label for="exampleEmail" sm={2}>
              Password <span style={{color: '#dc3545'}}>*</span>
            </Label>
            <Col sm={10}>
              <Input
                id="password"
                name="password"
                type="password"
                placeholder="Enter password"
                className="form-control"
                value={this.user.result.password}
                onChange={this.handleFormChange}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label style={{whiteSpace:"nowrap"}} for="exampleEmail" sm={2}>
              New Password <span style={{color: '#dc3545'}}>*</span>
            </Label>
            <Col sm={10}>
              <Input
                id="new_password"
                name="new_password"
                type="password"
                placeholder="Enter new password"
                value={this.state.new_password}
                onChange={this.handleFormChange}
              />
            </Col>
          </FormGroup>

          <FormGroup check row>
            <Col sm={{ size: 10, offset: 2 }}>
              <Button onClick={this.handleSave.bind(this)}>Submit</Button>
            </Col>
          </FormGroup>
        </Form>
        </BrowserView>
      </Layout>
    );
  }
}
export default withRouter(ChangePassword);
