import React, { useState, useEffect } from "react";
import Select from "react-select";
import { withRouter } from "react-router-dom";
import merchantAPI from "../../../api/MerchantAPI/merchantAPI";
import adminUserAPI from "../../../api/AdminUserAPI/adminUserAPI";
import { Select as SelectAnt} from "antd";
import eventAPI from "../../../api/EventAPI/eventAPI";
import Layout from "../../../components/Layout/Layout";
import { get, isEmpty } from "lodash";
import { toast } from "react-toastify";
import { getCurrentUser } from "../../../util/auth";
import { checkPermission } from "../../../util/helpers";

const userOptions = [{ value: "*", label: "All" }];

const { Option } = SelectAnt;
const customStyles = {
  control: () => ({
    flexWrap: "wrap",
    borderBottom: "2px solid #efefef",
    boxSizing: "border-box",
    transition: "all 100ms",
    outline: "0 !important",
    minHeight: "38px",
    justifyContent: "space-between",
    display: "flex",
  }),
  multiValue: () => ({
    display: "flex",
    borderRadius: "16px",
    transition: ".3s",
    height: "32px",
    lineHeight: "34px",
    margin: ".1rem .3rem .1rem 0",
    background: "#efefef",
    position: "relative",
    overflow: "hidden",
    outline: "0",
    color: "#444",
    fontSize: "1em",
    fontWeight: "400",
    letterSpacing: ".05rem",
    fontFamily: 'Roboto,"Helvetica Neue",sans-serif',
  }),
  multiValueLabel: () => ({
    fontFamily: 'Roboto,"Helvetica Neue",sans-serif',
    color: "#444",
    paddingLeft: ".75rem",
  }),
};

const CreateUser = (props) => {
  const [merchants, setMerchants] = useState([]);
  const [events, setEvents] = useState([]);

  const [roles, setRoles] = useState([  
    {object: "user", action: ""},
    {object: "overview", action: ""},
    {object: "attendant", action: ""},
    {object: "list", action: ""}
  ])
  const [roleExtra, setRoleExtra] = useState({
    merchant_ids: [],
    event_ids: []
  })
  const [username, setUsername] = useState('');
  const [fullName, setFullName] = useState('');
  const [password, setPassword] = useState('');

  const user = getCurrentUser();
  const isPermission = checkPermission(user, 'user');

  if (!isPermission) props.history.push('/index')

  useEffect(() => {
    getMerchants();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getEvents = async (merchants) => {
    const updatedEvents =[...events];
    await Promise.all(merchants.map(async (merchant) => {
      try {
        const response = await eventAPI.getEventsByMerchant(merchant.id);
        if (response) {
          response.map(event => {
            return updatedEvents.push({
              ...event,
              merchantId: merchant.id
            })
          });
        }          
      } catch (error) {
        console.log("Faile to fetch", error);
      }
    }));
    setEvents(updatedEvents);
  };
  
  const getMerchants = async () => {
    try {
      const merchants = await merchantAPI.getMerchants();
      if (merchants) {
        setMerchants(merchants);
        getEvents(merchants);
      }
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const _handleCreacteUser = async () => {
    if (isEmpty(username) || isEmpty(password) || isEmpty(fullName)) {
      return toast.error('Please fill in required fields', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      })
    }
    try {
      const formattedRoleExtra = {
        event_ids: roleExtra.event_ids.join(','),
        merchant_ids: roleExtra.merchant_ids.join(',')
      }

      const params = {
        type: "account",
        full_name: fullName,
        user_name: username,
        password: password,
        roles: roles,
        role_extra: formattedRoleExtra,
      };

      const response = await adminUserAPI.createAdminUser(params);
      if (response) {
        props.history.push("/users");
        toast.success('User Created Successfully!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _handleSelectRole = (_, event) => {
    const updatedRoles = [...roles];
    const { name, removedValue, option, action } = event;
    const data = option || removedValue;
    const role = { object: name, action: action === 'clear' ? '' : data.value };

    if (removedValue) {
      role.action = "";
    } else if (option) {
      role.action = data.value;
    }

    const roleIndex = updatedRoles.findIndex((r) => r.object === name);

    if (roleIndex < 0) {
      updatedRoles.push(role);
    } else {
      updatedRoles[roleIndex] = role;
    }
    setRoles(updatedRoles);
    // this.setState({ roles });
  };
  
  const _handleSelectMerchantIds = (value) => {
    const updatedMerchantIds = value.includes("all") ? ["all"] : value;
    setRoleExtra({
      merchant_ids: updatedMerchantIds,
      event_ids: []
    })
  };

  const _handleSelectEventIds = (value) => {
    setRoleExtra({
      ...roleExtra,
      event_ids: value
    })
  }
  
  return (
    <Layout>
      <div className="page-wrapper" style={{ margin: "auto" }}>
        <div className="page-container">
          <div className="main-content">
            <div className="section__content section__content--p30">
              <div
                className="container-fluid"
                style={{ margin: "-30px !important" }}
              >
                <div className="row">
                  <div className="col-md-12" style={{paddingLeft:0}}>
                    <div className="main-box">
                      <div className="main-box-header">
                        <h3 className="title-5 m-b-35">Create User</h3>
                      </div>
                      <div className="error-alert" id="error-alert"></div>
                      <div
                        className="main-box-body"
                        style={{ background: "white" }}
                      >
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <p
                                className="form-control-s2"
                                style={{
                                  fontSize: "1rem",
                                  marginBottom: "8px",
                                }}
                              >
                                User name (email) :
                                <span
                                  className="text-danger"
                                  style={{ color: "red" }}
                                >
                                  &nbsp;*
                                </span>
                              </p>
                              <input
                                className="form-control"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <p
                                className="form-control-s2"
                                style={{
                                  fontSize: "1rem",
                                  marginBottom: "8px",
                                }}
                              >
                                Full name:
                                <span
                                  className="text-danger"
                                  style={{ color: "red" }}
                                >
                                  &nbsp;*
                                </span>
                              </p>
                              <input
                                className="form-control"
                                value={fullName}
                                onChange={(e) => setFullName(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-6 pt-3">
                            <div className="form-group">
                              <p
                                className="form-control-s2"
                                style={{
                                  fontSize: "1rem",
                                  marginBottom: "8px",
                                }}
                              >
                                Password:
                                <span
                                  className="text-danger"
                                  style={{ color: "red" }}
                                >
                                  &nbsp;*
                                </span>
                              </p>
                              <input
                                type="password"
                                className="form-control"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-12 pt-5">
                            <div className="form-group">
                              <p
                                className="form-control-s2 pb-2"
                                style={{
                                  fontSize: "1rem",
                                  marginBottom: "8px",
                                }}
                              >
                                User Permission:
                              </p>
                              <table className="table table-hover">
                                <thead>
                                  <tr>
                                    <th
                                      scope="col"
                                      style={{
                                        width: "45%",
                                        fontSize: ".9rem",
                                      }}
                                    >
                                      OBJECT
                                    </th>
                                    <th
                                      scope="col"
                                      style={{
                                        width: "45%",
                                        fontSize: ".9rem",
                                      }}
                                    >
                                      ACTION
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td style={{ verticalAlign: "middle" }}>
                                      <span style={{ fontSize: "1rem" }}>
                                        User
                                      </span>
                                    </td>
                                    <td
                                      style={{
                                        paddingLeft: "0",
                                        marginLeft: "0",
                                      }}
                                    >
                                      <Select
                                        styles={customStyles}
                                        placeholder="Enter a new tag"
                                        defaultInputValue=""
                                        isMulti
                                        name="user"
                                        options={userOptions}
                                        className="multi-select-role"
                                        classNamePrefix="select"
                                        onChange={_handleSelectRole}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ verticalAlign: "middle" }}>
                                      <span style={{ fontSize: "1rem" }}>
                                        Over view
                                      </span>
                                    </td>
                                    <td
                                      style={{
                                        paddingLeft: "0",
                                        marginLeft: "0",
                                      }}
                                    >
                                      <Select
                                        styles={customStyles}
                                        placeholder="Enter a new tag"
                                        defaultInputValue=""
                                        isMulti
                                        name="overview"
                                        options={userOptions}
                                        className="multi-select-role"
                                        classNamePrefix="select"
                                        onChange={_handleSelectRole}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ verticalAlign: "middle" }}>
                                      <span style={{ fontSize: "1rem" }}>
                                        Attendants
                                      </span>
                                    </td>
                                    <td
                                      style={{
                                        paddingLeft: "0",
                                        marginLeft: "0",
                                      }}
                                    >
                                      <Select
                                        styles={customStyles}
                                        placeholder="Enter a new tag"
                                        defaultInputValue=""
                                        isMulti
                                        name="attendant"
                                        options={userOptions}
                                        className="multi-select-role"
                                        classNamePrefix="select"
                                        onChange={_handleSelectRole}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ verticalAlign: "middle" }}>
                                      <span style={{ fontSize: "1rem" }}>
                                        List order
                                      </span>
                                    </td>
                                    <td
                                      style={{
                                        paddingLeft: "0",
                                        marginLeft: "0",
                                      }}
                                    >
                                      <Select
                                        styles={customStyles}
                                        placeholder="Enter a new tag"
                                        defaultInputValue=""
                                        isMulti
                                        name="list"
                                        options={userOptions}
                                        className="multi-select-role"
                                        classNamePrefix="select"
                                        onChange={_handleSelectRole}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ verticalAlign: "middle" }}>
                                      <span style={{ fontSize: "1rem" }}>
                                        Coupon Codes
                                      </span>
                                    </td>
                                    <td
                                      style={{
                                        paddingLeft: "0",
                                        marginLeft: "0",
                                      }}
                                    >
                                      <Select
                                        styles={customStyles}
                                        placeholder="Enter a new tag"
                                        defaultInputValue=""
                                        isMulti
                                        name="coupon-codes"
                                        options={userOptions}
                                        className="multi-select-role"
                                        classNamePrefix="select"
                                        onChange={_handleSelectRole}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ verticalAlign: "middle" }}>
                                      <span style={{ fontSize: "1rem" }}>
                                        Merchant
                                      </span>
                                    </td>
                                    <td
                                      style={{
                                        paddingLeft: "0",
                                        marginLeft: "0",
                                      }}
                                    >
                                      <SelectAnt
                                        mode="multiple"
                                        showSearch
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        optionFilterProp={(optionA, optionB) =>
                                          optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }
                                        style={{
                                          width: "100%",
                                          marginBottom: "5px",
                                        }}
                                        value={roleExtra.merchant_ids}
                                        // defaultValue="all"
                                        onChange={_handleSelectMerchantIds}
                                      >
                                          <Option value="all">
                                            All
                                          </Option>
                                          {!get(roleExtra, 'merchant_ids', []).includes("all") && merchants.map(
                                            (merchant) => {
                                              return (
                                                <Option
                                                  key={merchant.id}
                                                  value={merchant.id}
                                                >
                                                  {merchant.merchant_name_en}
                                                </Option>
                                              );
                                            }
                                          )}
                                      </SelectAnt>
                                    </td>
                                  </tr>
                                  {!isEmpty(get(roleExtra, 'merchant_ids', [])) ? (
                                    <tr>
                                      <td style={{ verticalAlign: "middle" }}>
                                        <span style={{ fontSize: "1rem" }}>
                                          Events
                                        </span>
                                      </td>
                                      <td
                                        style={{
                                          paddingLeft: "0",
                                          marginLeft: "0",
                                        }}
                                      >
                                        <SelectAnt
                                          mode="multiple"
                                          showSearch
                                          filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                          optionFilterProp={(optionA, optionB) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                          }
                                          style={{
                                            width: "100%",
                                            marginBottom: "5px",
                                          }}
                                          value={roleExtra.event_ids}
                                          onChange={_handleSelectEventIds}
                                        >
                                          {events.map(
                                            (event) => {
                                              const isEventValid = 
                                              roleExtra.merchant_ids.includes("all") 
                                              || roleExtra.merchant_ids.includes(event.merchantId)

                                              return isEventValid ? (
                                                <Option
                                                  key={event.id}
                                                  value={event.id}
                                                >
                                                  {event.name_en}
                                                </Option>
                                              ) : null;
                                            }
                                          )}
                                        </SelectAnt>
                                      </td>
                                    </tr>
                                  ) :null}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div
                            className="col-md-12 pt-5"
                            style={{ textAlign: "right" }}
                          >
                            <button
                              type="submit"
                              className="btn btn-primary btn-lg btn-float"
                              onClick={_handleCreacteUser}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {this.state.spinner ? (
          <div
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              margin: "0 auto",
              opacity: 0.5,
              width: "100%",
              height: "100%",
              backgroundColor: "black",
            }}
          >
            <div style={{ position: "absolute", top: "50%", left: "50%" }}>
              <LoadingOverlay
                active={this.state.spinner}
                spinner={<PacmanLoader color="#1794fc" size="30px" />}
              ></LoadingOverlay>
            </div>
          </div>
        ) : null} */}
      </div>
    </Layout>
  );
}
export default withRouter(CreateUser);
