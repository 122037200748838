import React, { useEffect, useRef, useState } from "react";
import get from "lodash/get";
import { withRouter } from "react-router-dom";
import { Select, Button, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import * as classes from "./CouponCodes.module.css";
import { isMobile } from "react-device-detect";
import { CSVLink } from "react-csv";
import Pagination from "react-js-pagination";
import { getCurrentUser } from "../../util/auth";
import eventAPI from "../../api/EventAPI/eventAPI";
import { isEmpty } from "lodash";
import Layout from "../../components/Layout/Layout";
import PermissionDeniedModal from "../../components/PermissionDeniedModal/PermissionDeniedModal";
import TextModal from "../../components/TextModal/TextModal";
import { checkPermission, currencyFormat, formatNumber } from "../../util/helpers";
import Loader from "../../components/Loader/Loader";
import couponAPI from "../../api/CouponAPI/couponAPI";
import moment from "moment";

const { Option } = Select;

const object = "coupon-codes";

const DEFAULT_PAGE_SIZE = 20;

function getStatusText(status) {
  switch(status) {
    case 'created': return 'Created';
    case 'using': return 'Using';
    case 'out_of_use': return 'Out Of Use';
    case 'expired': return 'Expired';
    default: return status;
  }
}

function getDiscount(type, value = 0) {
  switch(type) {
    case 'percent': return `${value}%`;
    case 'fix_price': return currencyFormat(value);
    case 'quantity_discount': return currencyFormat(value);
    default: return value;
  }
}
const CouponCodes = (props) => {
  const { location } = props;
  const { search, pathname } = location;

  const query = new URLSearchParams(search);
  const event_id = query.get('event_id') || '';
  const status = query.get('status') || '';
  const q = query.get('q') || '';

  const [events, setEvents] = useState([]);
  // const [eventData, setEventData] = useState({
  //   eventList: [],
  //   selectedEventId: eventId
  // });
  const [couponCodeData, setCouponCodeData] = useState({
    coupons: [],
    paging: null
  });
  const [couponCodeExportData, setCouponCodeExportData] = useState([]);

  const [filterData, setFilterData] = useState({
    event_id,
    status,
    q,
  });

  const [isShowNoDataModal, setIsShowNoDataModal] = useState(false);
  const [isShowPermissionModal, setIsShowPermissionModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const csvInstance = useRef(null);

  const user = getCurrentUser();

  useEffect(() => {
    const isPermission = checkPermission(user, object);
    if (isPermission) {
      getUserAllowedEvents();
    } else {
      setIsShowPermissionModal(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setFilterData({
      event_id,
      status,
      q,
    });
    if (event_id) {
      getCouponCodes(1);
    }
  }, [search]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isEmpty(couponCodeExportData) && csvInstance && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setCouponCodeExportData([]);
      });
    }
  }, [couponCodeExportData]);

  const getUserAllowedEvents = async () => {
    const userAllowedEventIds = get(user, 'result.role_extra.event_ids', '');
    const userAllowedMerchantIds = get(user, 'result.role_extra.merchant_ids', '');

    const userAllowedEventIdsArr = get(user, 'result.role_extra.event_ids', '').split(',');
    const userAllowedMerchantIdsArr = get(user, 'result.role_extra.merchant_ids', '').split(',');

    let eventResult = [];

    try {
      if (userAllowedMerchantIds === 'all') { // Show all events from all mechants
        const eventResponse = await eventAPI.getEvents();
        if (!isEmpty(eventResponse)) {
          if (userAllowedEventIds === 'all' || isEmpty(userAllowedEventIds)) {
            eventResult = eventResponse;
          } else {
            eventResult = eventResponse.filter(event => userAllowedEventIdsArr.includes(event.id));
          }
        }
      } else { // Show events based on user merchants
        await Promise.all(userAllowedMerchantIdsArr.map(async merchantId => { // Loop mechants to get merchant's events
          const eventByMerchantIdResponse = await eventAPI.getEventsByMerchant(merchantId);
          if (!isEmpty(eventByMerchantIdResponse)) {
            eventResult = [ ...eventResult, ...eventByMerchantIdResponse ]
          }
        }));
        if (userAllowedEventIds === 'all' || isEmpty(userAllowedEventIds)) {
          //
        } else {
          eventResult = eventResult.filter(event => userAllowedEventIdsArr.includes(event.id));
        }
      }
      setEvents(eventResult);
      isEmpty(event_id) && updateQuery('event_id', eventResult[0].id);
    } catch (error) {
      console.log("Faile to fetch", error);
    }
  }

  const getCouponCodeExportData = async () => {
    setIsLoading(true);
    try {
      const response = await couponAPI.getCouponCodeExportData(search);
      if (response) {
        setCouponCodeExportData(response.coupons);
      }
    } catch (error) {
      setCouponCodeExportData([]);
      alert(get(error, 'response.massage', 'Error has occurred!'));
      console.log("Faile to fetch", error);
    }
    setIsLoading(false);
  };

  const getCouponCodes = async (page) => {
    setCouponCodeExportData([]);
    setIsLoading(true);
    try {
      const res = await couponAPI.getCouponCodes(page, search, DEFAULT_PAGE_SIZE);
      setCouponCodeData({
        coupons: get(res, 'coupons', []),
        paging: get(res, 'paging', [])
      });
      isEmpty(get(res, 'coupons', [])) && setIsShowNoDataModal(true);
    } catch (error) {
      setIsShowNoDataModal(true);
      setCouponCodeData({
        coupons: [],
        paging: null
      })
      console.log("Faile to fetch", error);
    }
    setIsLoading(false);
  };

  const updateQuery = (key, value) => {
    let searchParams = new URLSearchParams(search);
    searchParams.set(key, value);
    props.history.push({
      pathname: pathname,
      search: searchParams.toString()
    });
  }

  const _handleFilterDataChange = (key, value) => {
    setFilterData({
      ...filterData,
      [key]: value || ''
    })
  }

  const _handlePageChange = (page) => {
    getCouponCodes(page);
    window.scrollTo(0, 0);
  }

  const _handleSubmitFilter = async () => {
    props.history.replace({
      pathname: pathname,
      search: "?" + new URLSearchParams({
        ...filterData
      }).toString()
    });
  }

  return (
    <Layout>
      <div 
        style={{ marginBottom: "10px" }} 
        className={!isMobile ? "d-flex flex-wrap: wrap justify-content-start align-items-center": ''}>
        <Select
          showSearch
          style={{ 
            width: isMobile ? "100%" : 300,
            marginRight: isMobile ? 0 : 6,
            marginBottom: 5
          }}
          placeholder="Events"
          value={isEmpty(filterData.event_id) ? null : filterData.event_id}
          onChange={(value) => _handleFilterDataChange('event_id', value)}
          filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
          optionFilterProp={(optionA, optionB) =>
            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
          }
        >
          {events.map((event) => {
            return (
              <Option key={event.id} value={event.id}>
                {event.name_en}
              </Option>
            );
          })}
        </Select>
        <Select
          style={{
            width: isMobile ? "100%" : 150,
            marginRight: isMobile ? 0 : 6,
            marginBottom: 5
          }}

          placeholder="Status"
          allowClear={true}
          onClear={() => _handleFilterDataChange('status', '')}
          value={isEmpty(filterData.status) ? null : filterData.status}
          onChange={(value) => _handleFilterDataChange('status', value)}
        >
          <Option value="created">Created</Option>
          <Option value="using">Using</Option>
          <Option value="out_of_use">Out Of Use</Option>
          <Option value="expired">Expired</Option>
        </Select>
        <Input
          style={{
            width: isMobile ? "49%" : 200,
            marginRight: isMobile ? 0 : 6,
            marginBottom: 5
          }}

          type="text"
          name="search"
          placeholder={`Code`}
          value={get(filterData, 'q', '')}
          onChange={(e) => _handleFilterDataChange('q', e.target.value)}
        />
        <Button
          className={classes.buttonSearch}
          //  className={classes.search}
          type="primary"
          icon={<SearchOutlined />}
          style={{
            width: isMobile ? '48%' :  100,
            marginRight: isMobile ? 0 : 6,
            marginBottom: 5
          }}
          onClick={_handleSubmitFilter}
        >
          Search
        </Button>
        {!isEmpty(couponCodeData.coupons) ? (
          <div style={{ float: "left", width: isMobile ? "48%" : 'fit-content', height: 32, marginBottom: 5 }}>
            <Button
              className={'btn'}
              type="primary"
              style={{
                backgroundColor: "#DC143C",
                border: 'none',
                width: isMobile ? '100%' :  100,
                marginRight: isMobile ? 0 : 6,
                marginBottom: 5
              }}
              onClick={getCouponCodeExportData}
            >
              Export Data
            </Button>

            <CSVLink
              data={couponCodeExportData}
              ref={csvInstance}
              filename={"coupon-code.csv"}
              className="btn"
              style={{
                backgroundColor: "#DC143C",
                width: "100%",
                height: 32,
                color: "white",
                fontSize: "13px",
                display: 'none'
              }}
            >
              Export data
            </CSVLink>
          </div>
        ) : null}
      </div>
      {!isEmpty(couponCodeData.coupons) ? (
        <>
          <div style={{overflowX:"auto", width:"100%", marginTop: 16}}>
            <table
              className="table table-hover"
              style={{ borderRadius: "10px" }}
            >
              <tbody>
                <tr className={classes.backgroundTr} style={{textAlign:"center"}}>
                  <th scope="col" className={`${classes.backgroundTh} `}>
                    #
                    </th>
                  <th scope="col" className={`${classes.backgroundTh} `}>
                    Coupon Code
                  </th>
                  <th scope="col" className={`${classes.backgroundTh} `}>
                    Max Time Used
                  </th>
                  <th scope="col" className={`${classes.backgroundTh} `}>
                    Remaining
                  </th>
                  <th scope="col" className={`${classes.backgroundTh} `}>
                    Discount Condition
                  </th>
                  <th scope="col" className={`${classes.backgroundTh} `}>
                    Status
                  </th>
                  <th scope="col" className={`${classes.backgroundTh} `}>
                    Apply from
                  </th>
                  <th scope="col" className={`${classes.backgroundTh} `}>
                    Apply to
                  </th>
                </tr>
                {couponCodeData.coupons.map((couponCode, index) => {
                  return (
                    <tr
                      key={index}
                      style={{ fontSize: "14px", textAlign: "center" }}
                    >
                      <td className={`${classes.backgroundTd} `}>
                        <span>
                          {(get(couponCodeData, 'paging.current_page', 1) - 1) * DEFAULT_PAGE_SIZE +
                            index +
                            1}
                        </span>
                      </td>
                      <td className={`${classes.backgroundTd} `}>
                        <span>
                          {get(couponCode, 'code', '')}
                        </span>
                      </td>
                      <td className={`${classes.backgroundTd} `}>
                        <span> {formatNumber(get(couponCode, 'max_times_used', ''))} </span>
                      </td>
                      <td className={`${classes.backgroundTd} `}>
                        <span> {formatNumber(get(couponCode, 'remaining', ''))} </span>
                      </td>
                      <td className={`${classes.backgroundTd} `}>
                        <span>
                          {getDiscount(get(couponCode, 'discount_type', ''), get(couponCode, 'discount_value', 0))}
                        </span>
                      </td>
                      <td className={`${classes.backgroundTd} `}>
                        <span> {getStatusText(get(couponCode, 'status', ''))} </span>
                      </td>
                      <td className={`${classes.backgroundTd} `}>
                        <span>
                          {moment(get(couponCode, 'apply_from', '')).format('DD/MM/YYYY HH:mm:ss')}
                        </span>
                      </td>
                      <td className={`${classes.backgroundTd} `}>
                        <span>
                          {moment(get(couponCode, 'apply_to', '')).format('HH:mm DD/MM/YYYY HH:mm:ss')}
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
            <span>Total: {get(couponCodeData, 'paging.total_item', 0)}/{get(couponCodeData, 'paging.total', 0)}</span>
            <div className={classes.pagination} style={{flexGrow: 1, display: 'flex', justifyContent: 'flex-end'}}>
              {couponCodeData.paging !== null &&
              couponCodeData.paging.total_item > 20 ? (
                <Pagination
                  // hideFirstLastPages
                  pageRangeDisplayed={7}
                  activePage={couponCodeData.paging.current_page}
                  itemsCountPerPage={DEFAULT_PAGE_SIZE}
                  totalItemsCount={couponCodeData.paging.total_item}
                  onChange={_handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              ) : null}
            </div>

          </div>
        </>
      ) : null}
      <Loader isOpen={isLoading} />
      <PermissionDeniedModal 
        isOpen={isShowPermissionModal} 
        toggle={() => setIsShowPermissionModal(!isShowPermissionModal)}
      />
      <TextModal 
        isOpen={isShowNoDataModal} 
        toggle={() => setIsShowNoDataModal(!isShowNoDataModal)}
        bodyText={'No Data'}
      />
    </Layout>
  );
}
export default withRouter(CouponCodes);
