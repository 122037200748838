import { get } from 'lodash';
import React from 'react';
import { currencyFormat, formatNumber } from '../../../util/helpers';
import * as classes from "../OverviewTable.module.css";

const OrderPendingTable = ({ orderPendingInfo }) => {
  let orderPendingSubtotal = 0;
  let orderPendingGrandTotal = 0;

  return get(orderPendingInfo, 'order_pending_quantity', null) ? (
    <React.Fragment>
      <span style={{ paddingLeft: "0", fontSize: 28, fontWeight: 500 }}>
        {" "}
        Order Pending{" "}
      </span>
      <div className='table-responsive'>
        <table className="table table-hover">
          <colgroup>
          <col span="1" style={{width: '25%', minWidth: 150}} />
          <col span="1" style={{width: '25%', minWidth: 150}} />
          <col span="1" style={{width: '25%', minWidth: 150}} />
          <col span="1" style={{width: '25%', minWidth: 150}} />
          </colgroup>

          <tbody>
            <tr style={{backgroundColor: '#f0f0f0'}}>
              <th scope="col" className='text-center'>
                Payment medthod
              </th>
              <th scope="col" className='text-center'>
                Quanlity
              </th>
              <th scope="col" className='text-center'>
                Subtotal
              </th>
              <th scope="col" className='text-center'>
                Grand Total
              </th>
            </tr>

            {get(orderPendingInfo, 'order_pending_quantity', []).map((order, index) => {
              orderPendingSubtotal += get(order, 'subtotal', 0);
              orderPendingGrandTotal += get(order, 'grand_total', 0);
              return (
                <tr
                  key={index}
                  style={{ fontSize: "14px" }}
                >
                  <td className={classes.backgroundTd}>
                    <span
                      style={{
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {order.payment_method.replace("_", " ")}{" "}
                    </span>
                  </td>
                  <td className={classes.backgroundTd}>
                    <span className={classes.spanTable}>
                      {formatNumber(order.quantity)}
                    </span>
                  </td>
                  <td className={classes.backgroundTd}>
                    <span
                      style={{ float: "right" }}
                      className={classes.spanTable}
                    >
                      {currencyFormat(get(order, 'subtotal', 0))}
                    </span>
                  </td>
                  <td className={classes.backgroundTd}>
                    <span className={classes.spanTable}>
                      {currencyFormat(get(order, 'grand_total', 0))}
                    </span>
                  </td>
                </tr>
              )
            })}

            <tr style={{ fontSize: "14px" }}>
              <td className={classes.backgroundTd}>
                <span style={{ fontWeight: "bold" }}> Total </span>
              </td>
              <td className={classes.backgroundTd}>
                <span className={classes.spanTable}></span>
              </td>
              <td className={classes.backgroundTd}>
                <span
                  className={classes.spanTable}
                  style={{ float: "right" }}
                >
                  {currencyFormat(orderPendingSubtotal)}
                </span>
              </td>
              <td className={classes.backgroundTd}>
                <span className={classes.spanTable}>
                  {currencyFormat(orderPendingGrandTotal)}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </React.Fragment>
  ) : null
}

export default OrderPendingTable;