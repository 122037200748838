import React, { Component } from "react";

export default class Index extends Component {
    render() {
        return(
            <div className="page-wrapper">
            <div className="page-container">
              <div className="main-content">
                <div className="section__content section__content--p30">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-12">

                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    {/* <div className="copyright" style={{textAlign: 'center', paddingTop: '25%'}}>
                      <span style={{fontSize: '50px'}}>© 2020 All rights reserved. TechHaus Vietnam JSC</span>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
}