import { get, isEmpty } from 'lodash';
import React from 'react';
import { formatNumber } from '../../../util/helpers';

const AttendantInfoTable = ({ attendantInfo }) => {
  return !isEmpty(attendantInfo) ? (
    <div style={{ width: "100%" }}>
      <h3>
        Attendant info
      </h3>
      <table className="table table-hover mw-100" style={{width: 500, tableLayout: 'fixed'}}>
        <tbody>
          <tr style={{ backgroundColor: '#f5f5f5' }}>
            <th scope="col">Category</th>
            <th scope="col">Quantity</th>
          </tr>

          {get(attendantInfo, 'attendant_quantity_info', []).map((attendant, index) => (
            <tr key={index}>
              <td style={{textTransform: 'uppercase', fontWeight: '600'}}>{attendant.category_name_en}</td>
              <td>{formatNumber(attendant.quantity)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : null;
}

export default AttendantInfoTable;